import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Decimal from 'decimal.js';
import * as yup from 'yup';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import { DisbursementRequest, PurchaseMethod } from '../../../utils/transactionUtils';
import DollarField from '../../Form/DollarField';
import { formatCents, useExpandableState } from '../../../utils';
import PhoneField from '../../Form/PhoneField';
import AddressFields from '../../Form/AddressFields';
import { USE_OF_FUND_OPTIONS } from '../../../utils/constants';
import SelectField, { OptionType } from '../../Form/SelectField';
import MultiS3FileUploadField from '../../Form/MultiS3FileUploadField';
import { getAPIEndpoint } from '../../../utils/useAdminAPIData';
import { DISBURSEMENT_TYPE, VENDOR_TYPE } from '../../../api/queries/loans/useDisbursementLimits';
import InvalidAddressModal from './InvalidAddressModal';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import { cleanDollarAmount } from '../../../utils/form-utilities';
import humanize from 'humanize';

type FieldValues = {
  amount: string;
  useOfFunds: OptionType[];
  workStatements: Document[];
  vendorName?: string;
  vendorPhone?: string;
  vendorEmail?: string;
  vendorAddressLine1?: string;
  vendorAddressLine2?: string | null;
  vendorAddressCity?: string;
  vendorAddressState?: string;
  vendorAddressZip?: string;
  invoiceInfo?: string | null;
  requestReason?: string | null;
  hasInvoiceInfo?: boolean;
  purchaseMethodId?: string | null;
};

type Props = {
  loanId: string;
  disbursement?: DisbursementRequest;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  payType: DISBURSEMENT_TYPE;
  vendorType: VENDOR_TYPE;
  isQuickDeposit?: boolean;
  quickDepositAvailableFunds: number;
  availableFunds: number;
  loading: boolean;
};

type Document = {
  _id?: string;
  name: string;
  s3: string;
};

const phoneRegExp = /\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;

function DisbursementDetailForm(props: Props) {
  const {
    loanId,
    disbursement,
    isQuickDeposit = false,
    vendorType,
    payType,
    quickDepositAvailableFunds,
    availableFunds,
    loading,
    onCancel,
    onSubmit,
  } = props;
  const [purchaseMethods, setPurchaseMethods] = useState<Array<PurchaseMethod>>([]);
  const invalidAddressModal = useExpandableState();

  useEffect(() => {
    const getPMs = async () => {
      const pms = await getAPIEndpoint(`/notebook/loans/${loanId}/purchase-methods`, {
        params: { active: true },
      });
      setPurchaseMethods(pms);
    };

    getPMs();
  }, [loanId]);

  const { callAPI: validateAddressAPI, loadPending: validatingAddress } = useAdminAPICall({
    endpoint: `/notebook/addresses/validate`,
    method: 'POST',
  });

  const isCheck = payType === DISBURSEMENT_TYPE.CHECK;
  const isSingleVendor = vendorType === VENDOR_TYPE.SINGLE;

  const handleSubmit = async (data: FieldValues) => {
    if (payType === DISBURSEMENT_TYPE.CHECK) {
      try {
        const result = await validateAddressAPI({
          data: {
            address_line1: data.vendorAddressLine1?.trim(),
            address_line2: data.vendorAddressLine2?.trim(),
            city: data.vendorAddressCity?.trim(),
            state: data.vendorAddressState?.trim(),
            zip: data.vendorAddressZip?.trim(),
          },
        });
        if (!result.data?.deliverable) {
          invalidAddressModal.showExpand();
          return;
        }
      } catch (err) {
        invalidAddressModal.showExpand();
        return;
      }
    }

    onSubmit({
      amount: new Decimal(cleanDollarAmount(data.amount)).times(100).toNumber(),
      use_of_funds: data.useOfFunds.map((x: any) => x.value),
      work_statements: isQuickDeposit ? [] : data.workStatements.filter((w) => w.s3 && w.name),
      ...(isSingleVendor && {
        vendor: {
          name: data.vendorName?.trim(),
          phone: data.vendorPhone?.trim(),
          email: data.vendorEmail?.trim(),
          mailing_address_line1: data.vendorAddressLine1?.trim(),
          mailing_address_line2: data.vendorAddressLine2?.trim(),
          mailing_city: data.vendorAddressCity?.trim(),
          mailing_state: data.vendorAddressState?.trim(),
          mailing_zip: data.vendorAddressZip?.trim(),
        },
      }),
      ...(isCheck
        ? {
            invoice_info: data.hasInvoiceInfo ? data.invoiceInfo : '',
            request_reason: !data.hasInvoiceInfo ? data.requestReason : '',
          }
        : {
            purchase_method_id: data.purchaseMethodId,
          }),
    });
  };

  const schema = yup.object({
    amount: yup
      .string()
      .required('Amount is a required field'),
    useOfFunds: yup
      .array()
      .of(yup.object().shape({ value: yup.string(), label: yup.string() }))
      .min(1, 'Use of funds is a required field')
      .required(),
    ...(!isQuickDeposit && {
      workStatements: yup
        .array()
        .of(yup.object().shape({ s3: yup.string(), name: yup.string() }))
        .min(2, 'Work statements is a required field')
        .required(),
    }),
    ...(isSingleVendor && {
      vendorName: yup.string().required('Vendor name is a required field'),
      vendorPhone: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Vendor phone is a required field'),
      vendorAddressLine2: yup.string().nullable(),
    }),
    ...(isCheck
      ? {
          vendorEmail: yup.string().required('Vendor email is a required field'),
          vendorAddressLine1: yup.string().required('Vendor address line 1 is a required field'),
          vendorAddressCity: yup.string().required('Vendor address city is a required field'),
          vendorAddressState: yup.string().required('Vendor address state is a required field'),
          vendorAddressZip: yup.string().required('Vendor address zip is a required field'),
          hasInvoiceInfo: yup.boolean().required(),
          invoiceInfo: yup.string().when('hasInvoiceInfo', {
            is: true,
            then: s => s.required('Vendor invoice info is a required field'),
            otherwise: s => s.notRequired(),
          }),
          requestReason: yup.string().when('hasInvoiceInfo', {
            is: false,
            then: s => s.required('Vendor request reason is a required field'),
            otherwise: s => s.notRequired(),
          }),
        }
      : {
          vendorEmail: yup.string().nullable(),
          vendorAddressLine1: yup.string().nullable(),
          vendorAddressCity: yup.string().nullable(),
          vendorAddressState: yup.string().nullable(),
          vendorAddressZip: yup.string().nullable(),
          invoiceInfo: yup.string().nullable(),
          purchaseMethodId: yup.string().required('Purchase method is a required field'),
        }),
  });

  const useOfFunds = (disbursement?.use_of_funds ?? []).map((value) =>
    USE_OF_FUND_OPTIONS.find((option) => option.value === value)
  );

  const form = useForm<FieldValues>({
    defaultValues: {
      amount: disbursement?.amount
        ? humanize.numberFormat(new Decimal(disbursement.amount).dividedBy(100).toFixed(2), 2)
        : undefined,
      vendorName: disbursement?._vendor?.name,
      vendorPhone: disbursement?._vendor?.phone,
      vendorEmail: disbursement?._vendor?.email,
      vendorAddressLine1: disbursement?._vendor?.address_line1,
      vendorAddressLine2: disbursement?._vendor?.address_line2,
      vendorAddressCity: disbursement?._vendor?.city,
      vendorAddressState: disbursement?._vendor?.state,
      vendorAddressZip: disbursement?._vendor?.zip,
      invoiceInfo: disbursement?.invoice_info || '',
      requestReason: disbursement?.request_reason || '',
      hasInvoiceInfo: !Boolean(disbursement?.request_reason),
      useOfFunds,
      purchaseMethodId: disbursement?.purchase_method_id,
      workStatements: [...(disbursement?.workStatements ?? []), { s3: '', name: '' }],
    },
    resolver: yupResolver(schema),
  });
  const {
    formState: { isSubmitting },
    watch,
  } = form;

  const values = watch();
  const maxAmount =
    Number(isQuickDeposit ? quickDepositAvailableFunds : availableFunds) +
    Number(disbursement?.amount ? cleanDollarAmount(disbursement.amount) : 0);

  useEffect(() => {
    if (purchaseMethods.length && !values.purchaseMethodId) {
      form.setValue('purchaseMethodId', purchaseMethods[0]._id);
    }
  }, [purchaseMethods, values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form {...form} onSubmit={handleSubmit}>
        <DollarField
          name="amount"
          label="Amount"
          max={maxAmount / 100}
          subLabel={<>Available funds: {formatCents(maxAmount)}</>}
        />
        {!isQuickDeposit && isSingleVendor && (
          <>
            <Field
              name="vendorName"
              label="Vendor Name"
              {...(isCheck && {
                subLabel: <>The name the check will be made out to.</>,
              })}
            />
            <PhoneField name="vendorPhone" label="Vendor Phone Number" />
            <Field
              name="vendorEmail"
              label={`Vendor Email Address${isCheck ? '' : ' (optional)'}`}
              {...(isCheck && {
                subLabel: (
                  <>
                    An email to the vendor will be sent to this address notifying them when the
                    check is in the mail.
                  </>
                ),
              })}
            />
            <AddressFields
              label={`Vendor Mailing Address${isCheck ? '' : ' (optional)'}`}
              addressLine1FieldName="vendorAddressLine1"
              addressLine2FieldName="vendorAddressLine2"
              cityFieldName="vendorAddressCity"
              stateFieldName="vendorAddressState"
              zipFieldName="vendorAddressZip"
            />
            {isCheck && (
              <>
                {values.hasInvoiceInfo ? (
                  <>
                    <Field
                      name="invoiceInfo"
                      label="Invoice Number"
                      subLabel={<>This will appear on the check and in the email to the vendor.</>}
                      controlProps={{ maxLength: 18 }}
                    />
                    <div className="d-flex justify-content-end mt-2">
                      <Button
                        variant="link"
                        className="inline"
                        onClick={() => form.setValue('hasInvoiceInfo', false)}
                      >
                        I don’t have an invoice number
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Field
                      name="requestReason"
                      label="Description of Services"
                      subLabel={<>This will appear on the check and in the email to the vendor.</>}
                      controlProps={{ maxLength: 120 }}
                    />
                    <div className="d-flex justify-content-end mt-2">
                      <Button
                        variant="link"
                        className="inline"
                        onClick={() => form.setValue('hasInvoiceInfo', true)}
                      >
                        Enter an invoice number instead
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        <SelectField
          name="useOfFunds"
          label="Use of Funds"
          placeholder="Use of Funds"
          closeMenuOnSelect={false}
          isMulti
          options={USE_OF_FUND_OPTIONS}
        />
        {!isCheck && (
          <Field
            name="purchaseMethodId"
            label="Destination Bank Account"
            controlProps={{ as: 'select' }}
          >
            {purchaseMethods.map((pm) => (
              <option key={pm._id} value={pm._id} selected={values.purchaseMethodId === pm._id}>
                Account ending in **{pm.lastFour}
              </option>
            ))}
          </Field>
        )}
        {!isQuickDeposit && (
          <MultiS3FileUploadField
            name="workStatements"
            label="Documentation"
            subLabel="Please ensure the document clearly states the vendor’s name, vendor’s address, an explanation of work performed and amount owed."
          />
        )}

        <SubmitError />

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={onCancel} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner
            variant="primary"
            type="submit"
            loading={isSubmitting || loading || validatingAddress}
          >
            Confirm
          </ButtonWithSpinner>
        </div>
      </Form>
      <InvalidAddressModal modal={invalidAddressModal} />
    </>
  );
}

export default DisbursementDetailForm;
