import React, { useEffect } from 'react';
import * as _ from 'lodash';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import { Flag, FLAG_STATUS } from '../../api/queries/loan-applications/useUnderwritingFlags';
import ButtonWithSpinner from '../ButtonWithSpinner';
import MultiSelectField from '../Form/MultiSelectField';

type FieldValues = {
  flagStatus: FLAG_STATUS;
  denyReasons: Array<string>;
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => Promise<void>;
  flag: Flag;
};

export default function EditUnderwritingFlagModal (props: Props) {
  const { expand, onSubmit, flag } = props;
  const denyLabel = flag.type === 'force_ineligible_flag' ? 'Ineligible' : 'Deny';

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${flag.loan_application_id}/flags/${flag._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({
      data: {
        flagStatus: data.flagStatus,
        denyReasons: data.flagStatus === FLAG_STATUS.DENY ? data.denyReasons : [],
      },
    });
    await onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    flagStatus: yup.string(),
    denyReasons: yup.array(yup.string()).required('Please select at least one reason'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      flagStatus: flag.flag_status,
      denyReasons: flag.deny_reasons,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isSubmitSuccessful, isDirty }, reset, watch, getValues } = methods;
  const flagStatus = watch('flagStatus');

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful, reset, getValues]);

  const denyReasonsOptions = (Object.keys(flag.deny_reasons_options_for_select) || []).map((reason) => ({
    value: reason,
    label: flag.deny_reasons_options_for_select[reason],
  }));

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide} data-qa='edit-fact-modal'>
      <Modal.Header>
        <Modal.Title>
          {flag.human_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='flagStatus' label='Status' controlProps={{ as: 'select' }}>
            <option value={ FLAG_STATUS.NEEDS_REVIEW }>Needs Review</option>
            <option value={ FLAG_STATUS.DISREGARD }>Disregard</option>
            <option value={ FLAG_STATUS.DENY }>{denyLabel}</option>
          </Field>

          {
            !_.isEmpty(denyReasonsOptions) &&
            flagStatus === FLAG_STATUS.DENY && (
              <MultiSelectField
                name='denyReasons'
                placeholder='Please select...'
                label={`${denyLabel} Reasons`}
                options={denyReasonsOptions}
              />
            )
          }

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
