import React from 'react';
import Button from 'react-bootstrap/Button';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Modal from 'react-bootstrap/Modal';
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import ButtonWithSpinner from '../ButtonWithSpinner';
import useMutateReopenLoanApplication from '../../api/mutations/loan-applications/useMutateReopenLoanApplication';

type FieldValues = {
  notes: string;
  reason: string;
};

type ReopenApplicationModalProps = {
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
  loanApplication: { _id: string };
};

export default function ReopenApplicationModal(props: ReopenApplicationModalProps) {
  const { loanApplication, show, onHide, onSubmit } = props;
  const { mutate } = useMutateReopenLoanApplication(loanApplication._id);

  const schema = yup.object().shape({
    notes: yup.string(),
    reason: yup.string().required('Please select a reason.'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: { notes: '', reason: '' },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;

  const handleSubmit = async (form: FieldValues) => {
    await mutate({
      notes: form.notes,
      reason: form.reason,
     });
    onSubmit();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Re-open application
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='reason' label='Reason' controlProps={{ as: 'select' }}>
            <option value="" disabled hidden>Select</option>
            <option value='Denial Escalation'>Denial Escalation</option>
            <option value='Error'>Error</option>
            <option value='Other'>Other</option>
          </Field>

          <Field name='notes' label='Notes' placeholder='Enter notes...' controlProps={{ as: 'textarea', rows: '4' }}/>

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
