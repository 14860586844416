import useQuery from '../../shared/useQuery';

enum ROLES {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
};

type Response = {
  _id: string;
  email: string;
  role: ROLES;
  canEditSystemSettings: boolean;
  fullName: string;
  pictureUrl: string;
};

export default function useSelf () {
  return useQuery<Response>('/notebook/admins/self', { shouldRetryOnError: false });
}
