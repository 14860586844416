import React from 'react';
import { useParams } from 'react-router-dom';
import { useInvestor } from '../../../api/queries/investors/useInvestors';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { LabelValueGrouping } from '../../../components';
import { Header } from '../../Layouts/Layout';

export default function InvestorsShowInfoPage () {
  const params = useParams<{ investorKey: string }>();
  const investorKey = params.investorKey as string;
  const investor = useInvestor(investorKey);

  return (
    <div className='searchPage mb-5'>
      <Header>
        {investorKey}
      </Header>
      <ShowInvestorLayout
        investorKey={ investorKey }
        selectedTab='info'
      >
        { investor.data ? <div className='px-4'>
          <LabelValueGrouping condensed data={investor.data} />
        </div> : <div className='px-4'>Loading…</div> }
      </ShowInvestorLayout>
    </div>
  );
}
