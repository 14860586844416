import * as _ from 'lodash';
import InlineError from '../../components/InlineError';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from '../Search/NotebookTable';
import moment from 'moment/moment';
import formatDollars from '../../utils/formatDollars';
import { trim } from 'lodash';
import useDisbursementRequests, { DisbursementRequest } from '../../api/queries/disbursement-requests/useDisbursementRequests';
import SuperadminAuthRequired from '../../components/SuperadminAuthRequired';
import { Header } from '../Layouts/Layout';
import SortableTh from '../../components/SortableTh';
import querystring from 'qs';
import { SEARCH_PAGE_SIZE } from '../Search/Search';
import Pagination from '../../components/Pagination';

export default function Disbursements () {
  const { status } = useParams<{ status: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const parsedParams = querystring.parse(location.search, { ignoreQueryPrefix: true });
  const page = parsedParams.page ? parseInt(parsedParams.page as string) : 1;

  const { data: disbursementsResponse, error } = useDisbursementRequests(status, {
    sortBy: parsedParams.sortBy as string || '',
    sortDirection: parsedParams.sortDirection as string || '',
    page: page.toString(),
    perPage: SEARCH_PAGE_SIZE.toString(),
  });
  const disbursements = disbursementsResponse?.disbursements;
  const total = disbursementsResponse?.total || 0;
  const maxPages = Math.ceil(total / SEARCH_PAGE_SIZE);

  const handlePagination = (newActivePage: number) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('page', newActivePage.toString());
    navigate(`?${urlParams.toString()}`);
  };

  return (
    <SuperadminAuthRequired>
      <div className='searchPage mb-5'>
        <Header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-white m-0 p-0 align-items-center">
              <li className="breadcrumb-item">Disbursements</li>
              <li className="breadcrumb-item active" aria-current="page">{_.capitalize(status)}</li>
            </ol>
          </nav>
        </Header>
        <div>
          {error && <InlineError>Error loading results. Please try again.</InlineError>}
          {!disbursements && <p className='text-muted ml-4 mt-3'>Loading...</p>}
          {
            disbursements &&
            <>
              {
                disbursements.length === 0 ?
                  <p className='ml-4 mt-3'>No results</p>
                :
                  <>
                    <DisbursementsTable disbursements={disbursements}/>
                    <div className='d-flex'>
                      <Pagination
                        page={page}
                        maxPages={maxPages}
                        onPageChange={handlePagination}
                        className='ml-auto mr-4'
                      />
                    </div>
                  </>
              }
            </>
          }
        </div>
      </div>
    </SuperadminAuthRequired>
  );
}

function cleanValue(val: string | null) {
  return !val ? '--' : val;
}

function DisbursementsTable ({ disbursements }: { disbursements: Array<DisbursementRequest> }) {
  return (
    <>
      <NotebookTable>
        <NotebookTableHeader>
          <SortableTh sortBy='Loan->User.last_name'>Name</SortableTh>
          <SortableTh sortBy='amount'>Amount</SortableTh>
          <SortableTh sortBy='type'>Type</SortableTh>
          <SortableTh sortBy='Loan->Partner.short_name'>Partner</SortableTh>
          <SortableTh sortBy='Loan->Portfolio.name'>Portfolio</SortableTh>
          <SortableTh sortBy='created_at' isDefault={true}>Requested</SortableTh>
        </NotebookTableHeader>
        <tbody>
        {
          disbursements.map(disbursement => (
            <NotebookTableRow
              key={disbursement.disbursement_request_id}
              to={`/loan-applications/${disbursement.loan_application_id}/servicing/activity`}
            >
              <td>{cleanValue(trim(`${disbursement.user_name ?? ''}`))}</td>
              <td>{formatDollars(disbursement.amount, { precision: 2, cents: true })}</td>
              <td>{cleanValue(disbursement.type)}</td>
              <td>{disbursement.partner_short_name}</td>
              <td>{disbursement.portfolio_name}</td>
              <td>{moment(disbursement.created_date).format('M/D/YYYY [at] h:mm a')}</td>
            </NotebookTableRow>)
          )
        }
        </tbody>
      </NotebookTable>
    </>
  );
}
