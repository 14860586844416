import React, { useMemo } from 'react';
import useExpandableState from '../../utils/useExpandableState';
import GenericModal from './GenericModal';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../Form/Form';
import DollarField from '../Form/DollarField';
import SubmitError from '../Form/SubmitError';
import { Button, FormGroup, FormLabel } from 'react-bootstrap';
import Field from '../Form/Field';
import { Loan } from '../BorrowerTabs/ServicingTab/Activity';
import { PurchaseMethod } from '../../utils/transactionUtils';
import Decimal from 'decimal.js';
import CardCheckbox from '../Form/CardCheckbox';
import ButtonWithSpinner from '../ButtonWithSpinner';
import useIdempotencyKey from '../../utils/useIdempotencyKey';
import { cleanDollarAmount } from '../../utils/form-utilities';

type FieldValues = {
  amount: string;
  effectiveDate: string;
  bankAccountId: string;
  notifyBorrower: boolean;
  markLoanRepaid: boolean;
  notes: string;
};

type Props = {
  modal: ReturnType<typeof useExpandableState>;
  loan: Loan;
  purchaseMethods: Array<PurchaseMethod>;
  onSubmit: () => void;
};

function SchedulePaymentModal (props: Props) {
  const { modal, loan, purchaseMethods, onSubmit } = props;
  const requestKey = useIdempotencyKey([modal.isExpanded]);

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${ loan._id }/payment/schedule`,
    method: 'POST',
  });

  const defaultValues = {
    amount: undefined,
    effectiveDate: '',
    bankAccountId: '',
    notifyBorrower: true,
    markLoanRepaid: false,
    notes: '',
  }
  const schema = yup.object().shape({
    amount: yup.string().test('amount', 'Please enter a postive amount', value => !!value && new Decimal(cleanDollarAmount(value)).greaterThan(0)),
    effectiveDate: yup.string().required('Please select an effective date'),
    bankAccountId: yup.string().required('Please select a bank account'),
    notes: yup.string(),
  });
  const methods = useForm<FieldValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting } } = methods;
  const closeModal = () => {
    modal.hideExpand();
    methods.reset(defaultValues);
  }
  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: {
      ...form,
      request_key: requestKey,
      amount: new Decimal(cleanDollarAmount(form.amount)).times(100).toNumber(),
    }})
    onSubmit();
    closeModal()
  };

  const buildOptions = useMemo(() => {
    const options = [];
    for (const pm of purchaseMethods) {
      options.push({
        label: `${ pm.accountType } ending in ${ pm.lastFour }`,
        value: pm._id,
      });
    }
    return options;
  }, [purchaseMethods]);

  return (
    <GenericModal expandable={modal} onClose={closeModal} title='Schedule payment'>
      <Form { ...methods } onSubmit={ handleSubmit }>
        <Field
          controlProps={{ className: 'FieldSelect', as: 'select' }}
          label='Bank Account'
          name='bankAccountId'
          secondaryLabel='The account the payment will come from.'
        >
          {buildOptions.map(option =>
            <option key={option.value} value={option.value}>{option.label}</option>
          )}
        </Field>
        <Field
          label='Effective Date'
          name='effectiveDate'
          controlProps={{ type: 'date' }}
        />
        <DollarField
          label='Amount'
          name='amount'
        />
        <Field name='notes' label='Internal Notes' placeholder='' controlProps={{ as: 'textarea' }}/>
        <FormGroup>
          <FormLabel>Notifications</FormLabel>
          <CardCheckbox name='notifyBorrower' label='Notify Borrower (by email)' />
        </FormGroup>
        <FormGroup>
          <FormLabel>Final Repayment</FormLabel>
          <CardCheckbox name='markLoanRepaid' label='Update loan to "Repaid" after this payment clears' />
        </FormGroup>

        <SubmitError/>

        <div className='d-flex justify-content-end mt-4'>
          <Button
            variant='secondary'
            onClick={closeModal}
            className='mr-2'
          >
            Cancel
          </Button>
          <ButtonWithSpinner variant='primary' type='submit' loading={ isSubmitting }>
            Schedule Payment
          </ButtonWithSpinner>
        </div>
      </Form>
    </GenericModal>
  );
}

export default SchedulePaymentModal;
