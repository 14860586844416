import React from 'react';
import useLoanApplications, { LoanApplicationList } from '../../api/queries/loan-applications/useLoanApplications';
import InlineError from '../../components/InlineError';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from '../Search/NotebookTable';
import { trim } from 'lodash';
import moment from 'moment/moment';
import { capitalize } from 'lodash';
import * as _ from 'lodash';
import { Header } from '../Layouts/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import querystring from 'qs';
import SortableTh from '../../components/SortableTh';
import { SEARCH_PAGE_SIZE } from '../Search/Search';
import Pagination from '../../components/Pagination';

export default function LoanApplications ({ status }: { status: string }) {
  const location = useLocation();
  const navigate = useNavigate();
  const parsedParams = querystring.parse(location.search, { ignoreQueryPrefix: true });
  const page = parsedParams.page ? parseInt(parsedParams.page as string) : 1;
  const { data: loanApplicationsResponse, error } = useLoanApplications(status, {
    sortBy: parsedParams.sortBy as string || '',
    sortDirection: parsedParams.sortDirection as string || '',
    page: page.toString(),
    perPage: SEARCH_PAGE_SIZE.toString(),
  });
  const loanApplications = loanApplicationsResponse?.loanApplications;
  const total = loanApplicationsResponse?.total || 0;
  const maxPages = Math.ceil(total / SEARCH_PAGE_SIZE);

  const handlePagination = (newActivePage: number) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('page', newActivePage.toString());
    navigate(`?${urlParams.toString()}`);
  };

  return (
    <div className='searchPage mb-5'>
      <Header>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white m-0 p-0 align-items-center">
            <li className="breadcrumb-item">Loan Applications</li>
            <li className="breadcrumb-item active" aria-current="page">{_.capitalize(status)}</li>
          </ol>
        </nav>
      </Header>
      <div>
        {error && <InlineError>Error loading results. Please try again.</InlineError>}
        {!loanApplications && <p className='text-muted ml-4 mt-3'>Loading...</p>}
        {
          loanApplications &&
          <>
            {
              loanApplications.length === 0 ?
                <p className='ml-4 mt-3'>No results</p>
              :
                <>
                  <LoanApplicationsTable loanApplications={loanApplications}/>
                  <div className='d-flex'>
                    <Pagination
                      page={page}
                      maxPages={maxPages}
                      onPageChange={handlePagination}
                      className='ml-auto mr-4'
                    />
                  </div>
                </>
            }
          </>
        }
      </div>
    </div>
  );
}

function cleanValue(val: string | null) {
  return !val ? '--' : val;
}

function humanizeCurrentView (currentView: string) {
  return currentView.replace(/[_-]/g, ' ').split(' ').map(word => capitalize(word)).join(' ');
}

function LoanApplicationsTable ({ loanApplications }: { loanApplications: LoanApplicationList }) {
  return (
    <>
      <NotebookTable>
        <NotebookTableHeader>
          <SortableTh sortBy='last_name'>Name</SortableTh>
          <SortableTh sortBy='current_view'>Current View</SortableTh>
          <SortableTh sortBy='Agent.last_name'>Agent</SortableTh>
          <SortableTh sortBy='Partner.short_name'>Partner</SortableTh>
          <SortableTh sortBy='created_at' isDefault={true}>Created</SortableTh>
        </NotebookTableHeader>
        <tbody>
        {
          loanApplications.map(loanApplication => (
            <NotebookTableRow
              key={loanApplication._id}
              to={`/loan-applications/${loanApplication._id}`}
            >
              <td>{cleanValue(trim(`${loanApplication.firstName ?? ''} ${loanApplication.lastName ?? ''}`))}</td>
              <td>{humanizeCurrentView(loanApplication.currentView)}</td>
              <td>{cleanValue(trim(`${loanApplication.agentFirstName ?? ''} ${loanApplication.agentLastName ?? ''}`))}</td>
              <td>{loanApplication.partnerShortName}</td>
              <td>{moment(loanApplication.createdAt).format('M/D/YYYY [at] h:mm a')}</td>
            </NotebookTableRow>)
          )
        }
        </tbody>
      </NotebookTable>
    </>
  );
}
