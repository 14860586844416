import React from 'react';
import FastForwardIcon from '@material-ui/icons/FastForward';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import humanize from 'humanize';
import AdminUserBadge from '../AdminUserBadge';
import { formatDate, useExpandableState } from '../../utils';
import InlineError from '../InlineError';
import ErrorBoundary from '../ErrorBoundary';
import AccelerationEventModal from './AccelerationEventModal';
import RemoveModal from './RemoveModal';
import { LOAN_STATES } from '../../utils/constants';
import { Loan } from '../../api/queries/types';
import DropdownToggle from '../DropdownToggle';
import useAccelerationEvent, { AccelerationEvent as Event } from '../../api/queries/loans/useAccelerationEvent';

export const ACCELERATION_EVENT_TYPE = {
  SALE: 'sale',
  ELA_CANCEL_CUSTOMER: 'ela_cancel_customer',
  ELA_CANCEL_COMPASS: 'ela_cancel_compass',
  HOUSE_NOT_PUT_ON_THE_MARKET: 'house_not_put_on_the_market',
  REPAY_LOAN_EARLY: 'repay_loan_early',
  FRAUD: 'fraud',
  OTHER: 'other',
}

export const ACCEL_TYPE_MAPPING = {
  [ACCELERATION_EVENT_TYPE.SALE]: {
    name: 'Home closing',
    name_past: 'Home closed',
    kind: 'success',
  },
  [ACCELERATION_EVENT_TYPE.ELA_CANCEL_COMPASS]: {
    name: 'Agent cancels Listing Agreement',
    name_past: 'Agent canceled Listing Agreement',
    kind: 'warning',
  },
  [ACCELERATION_EVENT_TYPE.ELA_CANCEL_CUSTOMER]: {
    name: 'Borrower cancels Listing Agreement',
    name_past: 'Borrower canceled Listing Agreement',
    kind: 'warning',
  },
  [ACCELERATION_EVENT_TYPE.HOUSE_NOT_PUT_ON_THE_MARKET]: {
    name: 'Not listed',
    name_past: 'Not listed',
    kind: 'warning',
    legacy: true,
  },
  [ACCELERATION_EVENT_TYPE.REPAY_LOAN_EARLY]: {
    name: 'Early repayment',
    name_past: 'Early repayment',
    kind: 'success',
    legacy: true,
  },
  [ACCELERATION_EVENT_TYPE.FRAUD]: {
    name: 'Fraud',
    name_past: 'Fraud',
    kind: 'danger',
  },
  [ACCELERATION_EVENT_TYPE.OTHER]: {
    name: 'Other',
    name_past: 'Other',
    kind: 'info',
  },
}

function AccelIcon (props: { canceled: boolean, eventType: string }) {
  let fill = 'inherit';
  let opacity = 1;
  if (props.canceled) {
    opacity = 0.25;
  } else if (ACCEL_TYPE_MAPPING[props.eventType].kind) {
    fill = `var(--${ ACCEL_TYPE_MAPPING[props.eventType].kind })`;
  }
  return (
    <FastForwardIcon style={{ fill, marginTop: -1, opacity }} />
  );
}

function AccelLabel (props: { event: Event }) {
  const eventDate = new Date(props.event.date);
  const now = new Date();
  let name;
  if (eventDate > now) {
    name = ACCEL_TYPE_MAPPING[props.event.type].name
  } else {
    name = ACCEL_TYPE_MAPPING[props.event.type].name_past
  }
  const cx = ['_Label'];
  if (props.event.canceled) {
    cx.push('strikethrough');
  }
  return (
    <span className={ cx.join(' ') }>
      <strong className='_Type ml-2'>
        { name }
      </strong> on <time className='_Date font-weight-bold'>
        { formatDate(props.event.date) }
      </time>
    </span>
  );
}

function AccelActor (props: { event: Event, dateFirst?: boolean }) {
  let verb;
  let actor;
  if (props.event.last_update_by_admin_id || props.event.last_update_by_user_id) {
    if (props.event._previous && props.event.canceled === props.event._previous.canceled) {
      verb = 'Updated';
    } else {
      if (props.event.canceled) {
        verb = 'Removed';
      } else {
        verb = 'Added';
      }
    }
    actor = props.event.last_update_by_admin_id ? (
      <AdminUserBadge admin_id={ props.event.last_update_by_admin_id } />
    ) : <span>Borrower</span>;
  } else {
    verb = 'Processed';
    actor = 'System';
  }
  const date = humanize.date('M j, Y', new Date(props.event.created_at));

  if (props.dateFirst) {
    return (
      <span className='_LastAction text-muted'>
        <time className='_ActionDate'>
          { date }
        </time> &mdash; <span className='_ActionLabel'>
          { verb }
        </span> by <span className='_ActionUser'>
          { actor }
        </span>
      </span>
    );
  }
  return (
    <div className='_LastAction text-muted'>
      <span className='_ActionLabel'>
        { verb }
      </span> by <span className='_ActionUser'>
        { actor }
      </span> on <time className='_ActionDate'>
        { date }
      </time>
    </div>
  );
}

function AccelNotes (props: { event: Event }) {
  if (!props.event.notes) {
    return null;
  }
  return (
    <div className='text-muted text-break'>
      { props.event.notes }
    </div>
  );
}

function HistoryToggle ({ expandableState, historyLength }: { expandableState: ReturnType<typeof useExpandableState>, historyLength: number }) {
  return (
    <Button data-qa='history-toggle' variant='link' onClick={ expandableState.toggleExpand }>
      {
        expandableState.isExpanded ? 'Hide History' : `Show History (${ historyLength })`
      }
    </Button>
  )
}

export function AccelerationEventUI (props: { event: Event, onEdit: () => void, onRemove: () => void }) {
  return (
    <div className='AccelerationEvent d-flex'>
      <div className='_Info d-flex'>
        <div>
          <AccelIcon eventType={ props.event.type } canceled={ props.event.canceled } />
        </div>
        <div>
          <AccelLabel event={ props.event } />
          <div className='small ml-2'>
            <AccelActor event={ props.event } />
            <br/>
            <AccelNotes event={ props.event } />
          </div>
        </div>
      </div>
      <div className='_Actions ml-auto'>
        <Dropdown>
          <DropdownToggle id='accel-event-actions' />
          <Dropdown.Menu>
            <Dropdown.Item onClick={ props.onEdit }>Update</Dropdown.Item>
            <Dropdown.Item onClick={ props.onRemove }>Remove</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

function AccelerationEventHistoryItem (props: { entry: Event }) {
  return (
    <tr className='AccelerationEventHistoryItem'>
      <td className='pr-2'>
        <AccelActor event={ props.entry } dateFirst={ true } />
      </td>
      <td className='pr-2'>
        <AccelIcon eventType={ props.entry.type } canceled={ props.entry.canceled } />
        <AccelLabel event={ props.entry } />
      </td>
      <td className='pr-2'>
      <AccelNotes event={ props.entry } />
      </td>
    </tr>
  );
}

export function AccelerationEventHistoryUI (props: { history: Array<Event> }) {
  return (
    <table className='AccelerationEventHistory small'>
      <tbody>
        {
          props.history.map((entry) => (
            <AccelerationEventHistoryItem key={ entry._id } entry={ entry } />
          ))
        }
      </tbody>
    </table>
  );
}


export function AccelerationEvent ({ loan }: { loan: Loan }) {
  const req = useAccelerationEvent(loan._id);
  const historyView = useExpandableState();
  const editModal = useExpandableState();
  const removeModal = useExpandableState();

  let actions = null;
  let content = null;
  let latestEventState = null;

  const canCreateAccelerationEvents = [LOAN_STATES.FUNDED, LOAN_STATES.COMPLETED, LOAN_STATES.REPAID].includes(loan.status); // Allows for accelerating a completed loan so that apparent term-maturities can be corrected.

  if (req.error) {
    content = (
      <InlineError>Unable to load Acceleration Event data.</InlineError>
    );
  } else if (req.isLoading) {
    content = 'Loading…';
  } else if (!req.data || !req.data[0]) {
    content = (
      <span className='text-muted'>No acceleration event</span>
    );
    if (canCreateAccelerationEvents) {
      actions = (
        <Button data-qa='add-event' variant='link' onClick={ editModal.showExpand }>
          Add
        </Button>
      );
    }
  } else {
    const entries: Array<Event> = req.data;
    entries.sort((a,b) => Date.parse(b.created_at) - Date.parse(a.created_at));
    entries.forEach((entry, i) => {
      entry._previous = entries[i + 1]; // Reverse chronological
    });
    latestEventState = entries[0];
    if (entries[0].canceled) {
      actions = (
        <>
          <Button data-qa='add-event' variant='link' onClick={ editModal.showExpand }>
            Add
          </Button>
          <HistoryToggle expandableState={historyView} historyLength={ req.data.length } />
        </>
      );
      content = (
        <>
          {
            historyView.isExpanded && req.data.length > 1 ? (
              <div>
                <AccelerationEventHistoryUI history={ req.data } />
              </div>
            ) : (<span className='text-muted'>No acceleration event</span>)
          }
        </>
      );
    } else {
      if (entries.length > 1) {
        actions = <HistoryToggle expandableState={historyView} historyLength={ entries.length } />;
      }
      content = (
        <>
          <AccelerationEventUI
            event={ latestEventState }
            onEdit={ editModal.showExpand }
            onRemove={ removeModal.showExpand }
          />
          {
            historyView.isExpanded && entries.length > 1 ? (
              <div>
                <AccelerationEventHistoryUI history={ entries } />
              </div>
            ) : null
          }
        </>
      );
    }
  }
  // TODO: refactor this wrapping into a LoanWidget?
  return (
    <Row className='mb-5'>
      <Col>
        <div className='d-flex justify-content-between mb-1'>
          <h4 className='mb-3'>Acceleration Event</h4>
          <div className='_ModuleActions'>
            { actions }
          </div>
        </div>
        <Card className='_ModuleContent p-3'>
          { content }
        </Card>
      </Col>
      { editModal.isExpanded && <AccelerationEventModal
        event={ latestEventState }
        show={editModal.isExpanded}
        onHide={editModal.hideExpand}
        onSubmit={() => {
          window.location.reload()
        }}
        loan={loan}
      /> }
      { latestEventState &&
        <RemoveModal
          event={ latestEventState }
          show={removeModal.isExpanded}
          onHide={removeModal.hideExpand}
          onSubmit={() => {
            window.location.reload()
          }}
          loan={loan}
        />
      }
    </Row>
  );
}

export default function AccelerationEventWidget (props: { loan: Loan }) {
  if (!props.loan) {
    return <>Loading…</> // TODO: nice spinners?
  }
  return (
    <ErrorBoundary errorText='Error loading the Acceleration Event widget'>
      <AccelerationEvent loan={ props.loan } />
    </ErrorBoundary>
  );
}
