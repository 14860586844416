import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import cx from 'classnames';

import styles from './ActivityDetail.module.scss';
import { MinimizedEntityId } from '../../../MinimizedEntityId';
import { formatCents, formatDate } from '../../../../utils';
import { USE_OF_FUND_OPTIONS } from '../../../../utils/constants';
import { CHECK_ACTIVITY_ACTIONS } from './CheckDetail';
import StaticAddress from '../../../StaticAddress';
import { DisbursementData, getRequestedByUserInfo } from '../../../../utils/transactionUtils';
import { VendorDocuments } from './VendorDocuments';
import ExternalLink from '../../../ExternalLink';

type Props = {
  data: DisbursementData;
  onVerifyInvoice: () => void;
  onEditDisbursement: () => void;
  onAction: (action: CHECK_ACTIVITY_ACTIONS) => void;
};

export const CheckDetailContent: React.FC<Props> = ({ data, onVerifyInvoice, onEditDisbursement, onAction }) => {
  const dr = data.disbursementRequest;
  const { _vendor: vendor, _checkActivity: checkActivity, status: drStatus } = dr;

  const useOfFunds = dr.use_of_funds
    .map((x: string) => {
      const option = USE_OF_FUND_OPTIONS.find((u) => u.value === x);
      return option ? option.label : x;
    })
    .join(', ');

  const handleAction = async (action: CHECK_ACTIVITY_ACTIONS) => {
    onAction(action);
  };

  const requestedByUserInfo = getRequestedByUserInfo(data.loan, data.requestedByUser);

  let status = 'Pending';

  if (checkActivity) {
    status = 'Pending';
    if (checkActivity.markedClearedAt) {
      status = 'Cleared';
    } else if (checkActivity.markedStoppedAt) {
      status = 'Stopped';
    } else if (checkActivity.markedCanceledAt) {
      status = 'Canceled';
    }
  } else if (drStatus === 'canceled') {
    status = 'Canceled';
  }

  const isEditable = dr.invoice_status === 'open';
  const isInvoiceOpen = dr.status !== 'canceled' && dr.invoice_status === 'open';

  return (
    <div>
      <Button
        className={cx('mt-2', styles.actionButton)}
        variant={isInvoiceOpen ? 'primary' : 'secondary'}
        onClick={onVerifyInvoice}
      >
        {isInvoiceOpen ? 'Review Invoice' : 'View Invoice'}
      </Button>
      {isEditable && (
        <Button
          block
          className={cx('mt-2', styles.actionButton)}
          variant="outline-primary"
          onClick={onEditDisbursement}
        >
          Edit Request
        </Button>
      )}
      {checkActivity && (
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-dark"
            id="dropdown-basic"
            className={cx(styles.actionButton, styles.dropdownToggler)}
          >
            Edit
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.dropdownMenu}>
            <Dropdown.Item
              onClick={() => handleAction(CHECK_ACTIVITY_ACTIONS.CLEARED)}
              disabled={
                !checkActivity.submittedAt ||
                !!(
                  checkActivity.markedClearedAt ||
                  checkActivity.markedCanceledAt
                )
              }
            >
              Mark as Cleared
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleAction(CHECK_ACTIVITY_ACTIONS.STOPPED)}
              disabled={
                !checkActivity.submittedAt ||
                !!(
                  checkActivity.markedClearedAt ||
                  checkActivity.markedCanceledAt ||
                  checkActivity.markedStoppedAt
                )
              }
            >
              Mark as Stopped
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleAction(CHECK_ACTIVITY_ACTIONS.CANCELED)}
              disabled={
                !!(
                  checkActivity.submittedAt ||
                  checkActivity.markedCanceledAt
                )
              }
            >
              Mark as Canceled
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <div className={styles.line}>
        <span className={cx(styles.title, 'mt-4')}>Transaction Details</span>
      </div>
      <div className={styles.line}>
        <span>ID</span>
        <span>
          <MinimizedEntityId id={checkActivity?._id ?? data.disbursementRequest._id} />
        </span>
      </div>
      <div className={styles.line}>
        <span>Effective Date</span>
        <span>{formatDate(data.latest.effective_date, '--')}</span>
      </div>
      <div className={styles.line}>
        <span>Type</span>
        <span>Check Disbursement</span>
      </div>
      <div className={styles.line}>
        <span>Amount</span>
        <span>{formatCents(dr.amount)}</span>
      </div>
      <div className={styles.line}>
        <span>Status</span>
        <span>{status}</span>
      </div>
      <div className={styles.line}>
        <span>Posted On</span>
        <span>{formatDate(dr.postedOn, '--')}</span>
      </div>
      <div className={cx(styles.line, 'mt-4')}>
        <span className={styles.title}>Disbursement Details</span>
      </div>
      {vendor && (
        <>
          <div className={styles.line}>
            <span>Vendor Name</span>
            <span>{vendor.name}</span>
          </div>
          <div className={styles.line}>
            <span>Vendor Phone</span>
            <span>{vendor.phone}</span>
          </div>
          <div className={styles.line}>
            <span>Vendor Email</span>
            <span>{vendor.email}</span>
          </div>
          <div className={styles.line}>
            <span>Vendor Address</span>
            <span>
              <StaticAddress address={vendor} />
            </span>
          </div>
        </>
      )}
      <div className={styles.line}>
        <span>Use of Funds</span>
        <span>{useOfFunds}</span>
      </div>
      <div className={styles.line}>
        <span>Documentation</span>
        <VendorDocuments documents={dr.workStatements} />
      </div>
      {requestedByUserInfo && (
        <div className={styles.line}>
          <span>Requested by</span>
          <span>{requestedByUserInfo}</span>
        </div>
      )}
      <div className={styles.line}>
        <span>Submitted on</span>
        <span>{formatDate(dr.created_at)}</span>
      </div>
      <div className={styles.line}>
        <span>Approved on</span>
        <span>
          {dr.invoice_status === 'verified' ? formatDate(dr.invoice_status_changed_at) : '--'}
        </span>
      </div>
      <div className={styles.line}>
        <span>Approved by</span>
        <span>{dr.invoice_status === 'verified' ? dr.adminName : '--'}</span>
      </div>
      {checkActivity && (
        <>
          <div className={cx(styles.line, 'mt-4')}>
            <span className={styles.title}>
              {
                dr.publicLinkUrl ? (
                  <ExternalLink
                    text='Check Details'
                    href={ dr.publicLinkUrl }
                  />
                ) : 'Check Details'
              }
            </span>
          </div>
          <div className={styles.line}>
            <span>Check #</span>
            <span>{`#${checkActivity.check_number}`}</span>
          </div>
          <div className={styles.line}>
            <span>Recipient Address</span>
            <span>
              {checkActivity.recipient_address && (
                <StaticAddress address={checkActivity.recipient_address} />
              )}
            </span>
          </div>
          <div className={styles.line}>
            <span>Mailing Status</span>
            <span>{checkActivity.mailing_status || '--'}</span>
          </div>
          <span className={styles.line}>
            {
              checkActivity.external_id ? (
                <ExternalLink
                  text='Lob Details'
                  href={ `https://dashboard.lob.com/${ checkActivity.external_id }` }
                />
              ) : null
            }
          </span>
        </>
      )}
    </div>
  );
};
