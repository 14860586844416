import React from 'react';

const ExternalLinkSvg = ({ size = 16, color = '#007bff' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox={`0 0 30 30`}
    fill='none'
    stroke={color}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <g fill='none' fill-rule='evenodd'>
      <path d='M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8' />
    </g>
  </svg>
);

export default ExternalLinkSvg;
