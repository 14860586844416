import React from 'react';
import moment from 'moment';
import { Alert, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import useAdminAPICall from '../../utils/useAdminAPICall';
import { validateBoolean } from '../../utils/form-utilities';
import Form from '../Form/Form';
import Field from '../Form/Field';
import CardCheckbox from '../Form/CardCheckbox';
import SubmitError from '../Form/SubmitError';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { getDateInTimezone } from '../../utils/datetime';
import { AccelerationEvent as Event } from '../../api/queries/loans/useAccelerationEvent';
import { Portfolio } from '../../api/queries/partners/usePortfolios';
import { ACCEL_TYPE_MAPPING, ACCELERATION_EVENT_TYPE } from './AccelerationEvent';

type FieldValues = {
  type: string | undefined;
  date: string;
  notice_date: string;
  notes: string;
  confirmed: string | undefined;
  notify: boolean;
};

type Props = {
  event: Event | null;
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
  loan: {
    _id: string;
    userId: string;
    termDate: string;
    portfolio: Portfolio | null;
  };
};

export default function AccelerationEventModal({ event, show, onHide, onSubmit, loan }: Props) {
  const verb = event ? 'Update' : 'Create';

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/acceleration-events`,
    method: 'POST',
  });

  const handleSubmit = async (values: FieldValues) => {
    await callAPI({
      data: {
        ...values,
        confirmed: values.confirmed || null,
        notify:  values.type === ACCELERATION_EVENT_TYPE.FRAUD ? false : (showNotify && values.notify),
        loan_id: loan._id,
        user_id: loan.userId,
      }
    });

    onSubmit();
  };

  const validateDateIsInPast = (date?: string) => date && moment(date).isBefore(moment(), 'day');
  const validateDateIsNotFuture = (date?: string) => date && moment(date).isSameOrBefore(moment(), 'day');

  const schema = yup.object().shape({
    type: yup.string().required('Please choose event type'),
    date: yup.string().required('Please choose event date'),
    notes: yup.string(),
    confirmed: yup.string().test('confirmed', 'Only events in the past may be confirmed', (value, context) => value === '' || !!validateDateIsNotFuture(context.parent.date)),
    notify: yup.boolean().required(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      type: event ? event.type : '',
      date: event ? event.date : '',
      notice_date: event ? event.notice_date : getDateInTimezone().format('YYYY-MM-DD'),
      notes: '',
      confirmed: event && validateBoolean(event.confirmed) ? `${event.confirmed}` : '',
      notify: true,
    },
    resolver: yupResolver(schema)
  });
  const { formState: { isSubmitting }, watch } = methods;
  const values = watch();

  const allowClosingInvoice = values.type === 'sale' && values.date && !validateDateIsInPast(values.date) && moment(values.date).isBefore(loan.termDate, 'day');
  const allowELACancelInvoice = (values.type === 'ela_cancel_customer' || values.type === 'ela_cancel_compass') && values.date && moment(values.date).isBefore(loan.termDate, 'day');
  const showNotify = allowClosingInvoice || allowELACancelInvoice;

  return <Modal show={show} onHide={onHide} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>
        {verb} Acceleration Event
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Form {...methods} onSubmit={handleSubmit}>
        <Field name='type' label='Type' controlProps={{ as: 'select' }}>
          <option value="">- Choose event type -</option>
          {
            Object.entries(ACCEL_TYPE_MAPPING).map(([key, type]) => (
              type.legacy ? null : <option key={key} value={key}>{type.name}</option>
            ))
          }
        </Field>

        { values.type === 'ela_cancel_compass' && loan.portfolio?.key === 'QUORUM' &&
          <Alert variant="warning">
            Note: With Quorum loans, this type of event WILL NOT auto complete the loan. The borrower will continue to have access to funds until their loan matures. Their agent will not receive any more notifications.
          </Alert>
        }

        <Field name='notice_date' label='Notice date' placeholder='Choose notice date' controlProps={{ type: 'date' }}/>

        <Field name='date' label='Event date' placeholder='Choose event date' controlProps={{ type: 'date' }}/>

        <Field name='notes' label='Notes' placeholder='Enter notes' controlProps={{ as: 'textarea' }} required={
          [
            ACCELERATION_EVENT_TYPE.OTHER,
            ACCELERATION_EVENT_TYPE.FRAUD,
          ].includes(values.type as any)
        } />

        <Field name='confirmed' label='Confirmed' controlProps={{ as: 'select' }}>
          <option value=''>Unknown</option>
          <option value='true'>Event did happen</option>
          <option value='false'>Event did not happen</option>
        </Field>

        { showNotify ? <CardCheckbox name='notify' disabled={ values.type === ACCELERATION_EVENT_TYPE.FRAUD } label={ values.type === ACCELERATION_EVENT_TYPE.SALE ? "Notify Borrower and Agent (by email)" : "Notify Borrower by Email" }/> : null }

        <div className="d-flex justify-content-end mt-4">
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>{verb}</ButtonWithSpinner>
        </div>

        <SubmitError/>
      </Form>
    </Modal.Body>
  </Modal>
}
