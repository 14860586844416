import useQuery from '../../shared/useQuery';

export type DisbursementRequest = {
  user_name: string;
  amount: number;
  created_date: string;
  disbursement_request_id: string;
  type: string;
  loan_application_id: string;
  partner_short_name: string;
  portfolio_name: string;
};

type DisbursementsResponse = {
  disbursements: Array<DisbursementRequest>;
  total: number;
};

type SortOptions = {
  sortBy?: string;
  sortDirection?: string;
  page?: string;
  perPage?: string;
};

export default function useDisbursementRequests (status?: string, options: SortOptions = {}) {
  const urlParams = new URLSearchParams(options);
  return useQuery<DisbursementsResponse>(status ? `/notebook/disbursement-requests/${status}?${urlParams.toString()}` : null);
}
