import useQuery from '../../shared/useQuery';

type LoanApplicationsResponse = {
  loanApplications: LoanApplicationList;
  total: number;
};

export type LoanApplicationList = Array<LoanApplication>;

export type LoanApplication = {
  _id: string;
  firstName: string;
  lastName: string;
  currentView: string;
  agentFirstName: string;
  agentLastName: string;
  partnerShortName: string;
  createdAt: string;
};

type SortOptions = {
  sortBy?: string;
  sortDirection?: string;
  page?: string;
  perPage?: string;
};

export default function useLoanApplications (status?: string, options: SortOptions = {}) {
  const urlParams = new URLSearchParams(options);
  return useQuery<LoanApplicationsResponse>(status ? `/notebook/loan-applications/${status}?${urlParams.toString()}` : null);
}
