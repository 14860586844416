import React from 'react'
import ExternalLinkSvg from '../images/ExternalLinkSvg'

type Props = {
  text: string;
  href: string;
  size?: number;
  target?: string;
}

export default function ExternalLink ({ text, href, size, target }: Props) {
  return (
    <>
      <a
        href={ href }
        target={ target || '_blank' }
        rel='noreferrer noopener'
      >
        { text }&nbsp;<ExternalLinkSvg size={ size }/>
      </a>
    </>
  )
}
