import React from 'react';
import Button from 'react-bootstrap/Button';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Modal from 'react-bootstrap/Modal';
import useAdminAPICall from '../../utils/useAdminAPICall';
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import ButtonWithSpinner from '../ButtonWithSpinner';
import DollarField from '../Form/DollarField';
import { Decimal } from 'decimal.js';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';
import { formatCents } from '../../utils';
import humanize from 'humanize';
import { cleanDollarAmount } from '../../utils/form-utilities';
import { Col, Container, Row } from 'react-bootstrap';
import { isNil } from 'lodash';
import useOfferedPlanSummary from '../../api/queries/loan-applications/useOfferedPlanSummary';
import styles from './EditLoanAmountModal.module.scss';

type FieldValues = {
  loanAmountDollars: string;
};

type EditLoanAmountModalProps = {
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
  loanApplication: LegacyLoanApplication;
  maxLoanAmount: number;
  minLoanAmount: number;
  originationFeeCents: number;
  aprBps: number;
  interestRateBps: number;
  requestedPrincipalCents: number;
};

export default function EditLoanAmountModal(props: EditLoanAmountModalProps) {
  const {
    loanApplication,
    show,
    onHide,
    onSubmit,
    maxLoanAmount,
    minLoanAmount,
    originationFeeCents,
    aprBps,
    interestRateBps,
    requestedPrincipalCents,
  } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${loanApplication._id}/loan-amount`,
    method: 'PUT',
  });

  const schema = yup.object().shape({
    loanAmountDollars: yup.string().required('Please enter a loan amount.')
      .test('amount', `Loan amount must be between ${formatCents(minLoanAmount)} and ${formatCents(maxLoanAmount)}.`, value => {
        const amountCents = new Decimal(cleanDollarAmount(value)).times(100);
        return amountCents.greaterThanOrEqualTo(minLoanAmount) && amountCents.lessThanOrEqualTo(maxLoanAmount);
      }),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      loanAmountDollars: loanApplication.principal ? humanize.numberFormat(loanApplication.principal, 0) : '',
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting }, watch } = methods;
  const loanAmountDollars = watch('loanAmountDollars');
  const loanAmountCents = new Decimal(cleanDollarAmount(loanAmountDollars) || 0).times(100).toNumber();
  const { data: offeredPlanSummary } = useOfferedPlanSummary(loanApplication._id, loanAmountCents);

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: {
      value: new Decimal(cleanDollarAmount(form.loanAmountDollars)).toNumber(),
      verified: true,
    } });

    onSubmit();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Edit loan amount
        </Modal.Title>
      </Modal.Header>
      <Form {...methods} onSubmit={handleSubmit}>
        <div className='p-3'>
          <DollarField
            label='Loan amount'
            name='loanAmountDollars'
            footnote={`Max available credit is ${formatCents(maxLoanAmount)}.`}
            precision={0}
          />

          <SubmitError/>
        </div>

        <Container className='border-top border-bottom p-3 bg-light small'>
          <Row className='px-3'>
            <Col xs={6}>
              <Row className='text-muted mb-2'>Preview</Row>
              <Row>Loan Amount</Row>
              <Row>Interest</Row>
              <Row>Origination Fee</Row>
              <Row>APR</Row>
            </Col>
            <Col xs={3}>
              <Row className='text-muted mb-2'>Before</Row>
              <Row>{!isNil(requestedPrincipalCents) ? formatCents(requestedPrincipalCents) : '--'}</Row>
              <Row>{!isNil(interestRateBps) ? `${new Decimal(interestRateBps).dividedBy(100).toNumber()}%` : '--'}</Row>
              <Row>{!isNil(originationFeeCents) ? formatCents(originationFeeCents) : '--'}</Row>
              <Row>{!isNil(aprBps) ? `${new Decimal(aprBps).dividedBy(100).toNumber()}%` : '--'}</Row>
            </Col>
            <Col xs={3}>
              <Row className='text-muted mb-2'>After</Row>
              <Row>{formatCents(loanAmountCents)}</Row>
              <Row className={ !isNil(offeredPlanSummary?.interestRateBps) && offeredPlanSummary?.interestRateBps !== interestRateBps ? styles.warning : '' }>
                {offeredPlanSummary && !isNil(offeredPlanSummary?.interestRateBps) ? `${new Decimal(offeredPlanSummary.interestRateBps).dividedBy(100).toNumber()}%` : '--'}
              </Row>
              <Row className={ !isNil(offeredPlanSummary?.originationFeeCents) && offeredPlanSummary?.originationFeeCents !== originationFeeCents ? styles.warning : '' }>
                {offeredPlanSummary && !isNil(offeredPlanSummary?.originationFeeCents) ? formatCents(offeredPlanSummary.originationFeeCents) : '--'}
              </Row>
              <Row className={ !isNil(offeredPlanSummary?.aprBps) && offeredPlanSummary?.aprBps !== aprBps ? styles.warning : '' }>
                {offeredPlanSummary && !isNil(offeredPlanSummary?.aprBps) ? `${new Decimal(offeredPlanSummary.aprBps).dividedBy(100).toNumber()}%` : '--'}
              </Row>
            </Col>
          </Row>
        </Container>

        <div className="d-flex justify-content-end p-3">
          <Button variant="secondary" onClick={onHide} className="mr-2">
            Cancel
          </Button>
          <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
            Confirm
          </ButtonWithSpinner>
        </div>
      </Form>
    </Modal>
  )
}
