import * as React from 'react';
import { PanelWithActions, MainPanel } from '../ExpandableCard';
import { Row, Col, Alert, Card } from 'react-bootstrap';
import AccelerationEventWidget from '../AccelerationEvent/AccelerationEvent';
import { formatCents, useAPIData, useExpandableState } from '../../utils';
import * as _ from 'lodash';
import FundsSummary from '../FundsSummary';
import { getDateInTimezone } from '../../utils/datetime';
import ModifyEligiblePortfolioModal from '../Modals/ModifyEligiblePortfolioModal';
import AdminContext, { ROLES } from '../../adminContext';
import InlineButton from '../InlineButton';
import { Loan } from '../../api/queries/types';
import CurrentPortfolio from '../CurrentPortfolio';
import ModifyPortfolioModal from '../Modals/ModifyPortfolioModal/ModifyPortfolioModal';
import { CancelPortfolioTransferModal } from '../Modals/CancelPortfolioTransferModal';
import { usePartner } from '../../api/queries/partners/usePartner';
import moment from 'moment';
import Decimal from 'decimal.js';
import AccountingSummary from '../AccountingSummary';
import TitleDetailsWidget from '../TitleDetailsWidget';

type Contract = Loan['contract'];

function getEligiblePortfoliosDisplay(loanEligiblePortfolios: Loan['eligiblePortfolios'] | null, defaultPortfolios: Array<string>) {
  if (!loanEligiblePortfolios || (loanEligiblePortfolios && loanEligiblePortfolios.length === 0)) {
    return ['—'];
  }

  return loanEligiblePortfolios.map((portfolio) => portfolio.name + (defaultPortfolios.includes(portfolio.key) ? ' (default)' : ''));
}

function termModificationDescription (fromContract: Contract, toContract: Contract) {
  return `Term length: ${ fromContract.features.TERM.months } Months to ${ toContract.features.TERM.months } Months`
}

function LoanModificationAlert ({ contracts }: { contracts: Array<Contract> }) {
  const latestContract = contracts[contracts.length - 1];
  const previousContract = contracts[contracts.length - 2];
  return (
    <Alert variant='warning'>
      <strong className="mr-2">Loan modification</strong>
      <span> { termModificationDescription(previousContract, latestContract) }</span>
    </Alert>
  );
}

function PortfolioCard ({ loan }: { loan: Loan }) {
  const modifyEligiblePortfolioModal = useExpandableState();
  const modifyPortfolioModal = useExpandableState();
  const cancelPortfolioTransferModal = useExpandableState();

  const adminContext = React.useContext(AdminContext);
  const partner =  usePartner(loan.partnerId);
  const defaultPortfolios = partner.data?.portfolios ? partner.data.portfolios.filter(p => Boolean(p.fallbackPortfolio)).map(p => p.fallbackPortfolio!.key)  : [];

  return (<>
    <h4 className='mb-3'>Portfolio</h4>
    <Card className='p-3 mb-5'>
      <Row>
        <Col className='d-flex flex-column'>
          <strong className='pb-2'>Eligible Portfolio(s)</strong>
          <Row>
            <Col className='pr-0 eligible_portfolios'>
              {getEligiblePortfoliosDisplay(loan.eligiblePortfolios, defaultPortfolios).join(', ')}
            </Col>
            <Col className='col-2 pl-0 d-flex'>
              {adminContext.adminRole === ROLES.SUPERADMIN && (
                <InlineButton
                  onClick={modifyEligiblePortfolioModal.showExpand}
                  className="my-auto"
                >
                  Edit
                </InlineButton>
              )}
            </Col>
          </Row>
        </Col>
        <Col className='d-flex flex-column '>
          <strong className='pb-2'>Current Portfolio</strong>
          <CurrentPortfolio
            loan={loan}
            modifyPortfolioModal={modifyPortfolioModal}
            cancelPortfolioTransferModal={cancelPortfolioTransferModal}
            superadmin={adminContext.adminRole === ROLES.SUPERADMIN}
          />
        </Col>
      </Row>
    </Card>
    <ModifyEligiblePortfolioModal
      expand   = { modifyEligiblePortfolioModal }
      onSubmit = { () => { window.location.reload() } }
      loan     = { loan }
    />
    <ModifyPortfolioModal
      expand   = { modifyPortfolioModal }
      onSubmit = { () => { window.location.reload() } }
      loan     = { loan }
    />
    <CancelPortfolioTransferModal
      modal    = { cancelPortfolioTransferModal }
      loan     = { loan }
    />
  </>);
}

function DatesSummary ({ loan, multipleContracts }: { loan: Loan, multipleContracts: boolean }) {
  const originationDate = loan.originationDate ? getDateInTimezone(loan.originationDate) : null;
  const originationDateDaysDiff = originationDate ? originationDate.diff(moment(), 'days') : null;
  const originationDateDaysDiffText = originationDateDaysDiff ? `(${Math.abs(originationDateDaysDiff)} days ${ originationDateDaysDiff < 0 ? 'ago' : 'away' })` : '(Today)';

  const drawPeriodEndDate = loan.drawPeriodEndDate ? getDateInTimezone(loan.drawPeriodEndDate) : null;
  const drawPeriodEndDateDaysDiff = drawPeriodEndDate ? drawPeriodEndDate.diff(moment(), 'days') : null;
  const drawPeriodEndDateDaysDiffText = drawPeriodEndDateDaysDiff ? `(${Math.abs(drawPeriodEndDateDaysDiff)} days ${ drawPeriodEndDateDaysDiff < 0 ? 'ago' : 'away' })` : '(Today)';

  const termDate = loan.termDate ? getDateInTimezone(loan.termDate) : null;
  const termDateDaysDiff = termDate ? termDate.diff(moment(), 'days') : null;
  const termDateDaysDiffText = termDateDaysDiff ? `(${Math.abs(termDateDaysDiff)} days ${ termDateDaysDiff < 0 ? 'ago' : 'away' })` : '(Today)';

  const maturityDate = loan.maturityDate ? getDateInTimezone(loan.maturityDate) : null;
  const maturityDateDaysDiff = maturityDate ? maturityDate.diff(moment(), 'days') : null;
  const maturityDateDaysDiffText = maturityDateDaysDiff ? `(${Math.abs(maturityDateDaysDiff)} days ${ maturityDateDaysDiff < 0 ? 'ago' : 'away' })` : '(Today)';

  const originalTermDate = loan.originalTermDate ? getDateInTimezone(loan.originalTermDate) : null;
  const originalTermDateDaysDiff = originalTermDate ? originalTermDate.diff(moment(), 'days') : null;
  const originalTermDateDaysDiffText = originalTermDateDaysDiff ? `(${Math.abs(originalTermDateDaysDiff)} days ${ originalTermDateDaysDiff < 0 ? 'ago' : 'away' })` : '(Today)';

  return (<>
    <span><strong>Origination Date:</strong> { originationDate ? <>{originationDate.format('ll')} <span className='text-secondary'>{originationDateDaysDiffText}</span></> : '-' }</span>
    <span><strong>Draw Period Ends:</strong> { drawPeriodEndDate ? <>{drawPeriodEndDate.format('ll')} <span className='text-secondary'>{drawPeriodEndDateDaysDiffText}</span></> : '-' }</span>
    { !multipleContracts ? (
        <span><strong>Term Date:</strong> { termDate ? <>{termDate.format('ll')} <span className='text-secondary'>{termDateDaysDiffText}</span></> : '-' }</span>
      ) : <>
        <span><strong>Original Term Date:</strong> { originalTermDate ? <>{originalTermDate.format('ll')} <span className='text-secondary'>{originalTermDateDaysDiffText}</span></> : '-' }</span>
        <span><strong>Modified Term Date:</strong> { termDate ? <>{termDate.format('ll')} <span className='text-secondary'>{termDateDaysDiffText}</span></> : '-' }</span>
      </>
    }
    <span><strong>Due Date:</strong> { maturityDate ? <>{maturityDate.format('ll')} <span className='text-secondary'>{maturityDateDaysDiffText}</span></> : '-' }</span>
  </>);
}

export default function SummaryTab ({ loan }: { loan: Loan }) {
  const hasModifications = loan.hasModifications;
  const contractReq = useAPIData(`/notebook/loans/${ loan._id }/contract/lending_agreement`, { abort: !hasModifications });

  const contracts = contractReq.data && contractReq.data.agreementHistory;

  return (
    <>
      {
        (hasModifications && contractReq.data) ? (
          <LoanModificationAlert contracts={ contractReq.data.agreementHistory } />
        ) : null
      }
      <PanelWithActions>
        <MainPanel>
          <div className='pb-5'>
            <h4 className='mb-3'>Loan Details</h4>
            <FundsSummary loanId={ loan._id } />
            <Card className='px-3 py-2 mb-5'>
              <Row>
                <Col className='d-flex flex-column col-5'>
                  <span><strong>APR:</strong> { !_.isNil(loan.aprBps) ? `${ new Decimal(loan.aprBps).dividedBy(100).toNumber() }%` : '-' }</span>
                  <span><strong>Origination Fee:</strong> { !_.isNil(loan.proratedOriginationFeeCents) ? formatCents(loan.proratedOriginationFeeCents) : '-' }</span>
                  { contracts?.length > 1 ?
                    <>
                      <span><strong>Original Term:</strong> { contracts[0].features.TERM.months } Months</span>
                      <span><strong>Modified Term:</strong> { contracts[contracts.length - 1].features.TERM.months } Months</span>
                    </>
                    : <span><strong>Term:</strong> { loan.termDescription }</span>
                  }
                  <span><strong>Draw Period:</strong> { loan.drawPeriods ? `${loan.drawPeriods} months` : '-'}</span>
                </Col>
                <Col className='d-flex flex-column '>
                  <DatesSummary loan={loan} multipleContracts={contracts?.length > 1}/>
                </Col>
              </Row>
            </Card>
            <AccelerationEventWidget loan={ loan } />
            { loan.isSelling ? <TitleDetailsWidget loan={ loan } /> : null }
            <AccountingSummary loanId={ loan._id } />
            <PortfolioCard loan={ loan }/>
          </div>
        </MainPanel>
      </PanelWithActions>
    </>
  );
}
