import useQuery from '../../shared/useQuery';

export type UnderwritingStep = {
  outcome: string;
  productSummaries: Array<{
    productId: string;
    productName: string;
    availableCreditCents: number;
    maxAllowedCreditCents: number;
    minCreditLimitCents: number;
  }>;
};

export type UnderwritingSummary = {
  requestedPrincipalCents: number;
  selectedProductName: string;
  aprBps: number;
  originationFeeCents: number;
  interestRateBps: number;
  prequalificationStep: UnderwritingStep;
  finalStep: UnderwritingStep;
};

export default function useUnderwritingSummary (loanApplicationId: string) {
  return useQuery<UnderwritingSummary>(`/notebook/loan-applications/${ loanApplicationId }/underwriting-summary`);
}
