import React, { useEffect, useState } from 'react';
import { Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import BooleanRadioField from '../../Form/BooleanRadioField';
import MultiSelectField from '../../Form/MultiSelectField';
import useExpandableState from '../../../utils/useExpandableState';
import ActivatePartnerIssuesModal from '../../Modals/ActivatePartnerIssuesModal';
import { startCase } from 'lodash';

type PartnerInfoFields = {
  active: string;
  name: string;
  brandName: string;
  legalName: string;
  shortName: string;
  waitlistEnabled: string;
  disallowedPropertyEntities: Array<string>;
  landingPage: string;
  theme: string;
  funnelStartView: string;
  cashBackEnabled: string;
  showFunnelIntro: string;
  forcedManualReview: string;
  launchDate: string;
  contractRenewalDate: string;
  annualIncreasePercentage: number;
  paymentsPageEnabled: string;
  denialDelayEnabled: string;
  choosePlanMode: string;
  expirationDaysAfterApplicationCreated: number;
  expirationDaysAfterApproval: number;
  expirationDaysAfterAuthorization: number;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

type ErrorMessage = {
  key: string;
  message: string;
};

export default function EditPartnerInfo (props: Props) {
  const { partner, onSubmit, onHide } = props;
  const activatePartnerIssuesModal = useExpandableState();
  const [issues, setIssues] = useState<Array<string>>([]);

  const { callAPI, data: apiResponse } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerInfoFields) {
    await callAPI({
      data: {
        name: data.name,
        brandName: data.brandName,
        legalName: data.legalName,
        shortName: data.shortName,
        active: data.active === 'true',
        waitlistEnabled: data.waitlistEnabled === 'true',
        disallowedPropertyEntities: data.disallowedPropertyEntities,
        landingPage: data.landingPage,
        theme: data.theme,
        funnelStartView: data.funnelStartView,
        cashBackEnabled: data.cashBackEnabled === 'true',
        showFunnelIntro: data.showFunnelIntro === 'true',
        forcedManualReview: data.forcedManualReview === 'true',
        launchDate: data.launchDate,
        contractRenewalDate: data.contractRenewalDate,
        annualIncreasePercentage: data.annualIncreasePercentage,
        paymentsPageEnabled: data.paymentsPageEnabled === 'true',
        denialDelayEnabled: data.denialDelayEnabled === 'true',
        choosePlanMode: data.choosePlanMode,
        expirationDaysAfterApplicationCreated: data.expirationDaysAfterApplicationCreated,
        expirationDaysAfterApproval: data.expirationDaysAfterApproval,
        expirationDaysAfterAuthorization: data.expirationDaysAfterAuthorization,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerInfoFields>({
    defaultValues: {
      name: partner.name,
      brandName: partner.brand_name,
      legalName: partner.legal_name,
      shortName: partner.short_name,
      active: partner.active.toString(),
      waitlistEnabled: partner.waitlistEnabled.toString(),
      disallowedPropertyEntities: partner.disallowedPropertyEntities,
      landingPage: partner.landingPage,
      theme: partner.theme,
      funnelStartView: partner.funnel_start_view,
      cashBackEnabled: partner.cashBackEnabled.toString(),
      showFunnelIntro: partner.showFunnelIntro.toString(),
      forcedManualReview: partner.forcedManualReview.toString(),
      launchDate: partner.launchDate,
      contractRenewalDate: partner.contractRenewalDate,
      annualIncreasePercentage: partner.annualIncreasePercentage,
      paymentsPageEnabled: partner.paymentsPageEnabled.toString(),
      denialDelayEnabled: partner.denialDelayEnabled.toString(),
      choosePlanMode: partner.choosePlanMode,
      expirationDaysAfterApplicationCreated: partner.expirationDaysAfterApplicationCreated,
      expirationDaysAfterApproval: partner.expirationDaysAfterApproval,
      expirationDaysAfterAuthorization: partner.expirationDaysAfterAuthorization,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, reset } = methods;

  useEffect(() => {
    if (apiResponse?.errors) {
      setIssues(apiResponse.errors.map((error: ErrorMessage) => error.key));
      activatePartnerIssuesModal.showExpand();
    }
  }, [apiResponse?.errors, methods]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <Field label='Name' name='name' required/>
      <Field label='Brand Name' name='brandName'/>
      <Field label='Legal Name' name='legalName'/>
      <Field label='Short Name' name='shortName'/>
      <BooleanRadioField
        label='Active'
        name='active'
        options={
          [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false'},
          ]
        }
      />
      <BooleanRadioField label='Waitlist' name='waitlistEnabled'/>
      <MultiSelectField
        label='Disallowed Property Entities'
        name='disallowedPropertyEntities'
        placeholder='Please select...'
        options={[
          { value: 'llc', label: 'LLC' },
          { value: 'corporation', label: 'Corporation' },
          { value: 'trust', label: 'Trust' },
          { value: 'estate', label: 'Estate' },
          { value: 'partnership', label: 'Partnership' },
        ]}
      />
      <Field label='Landing Page' name='landingPage' controlProps={{ as: 'select' }}>
        <option value='generic'>Generic</option>
        <option value='sell_side'>Sell Side</option>
      </Field>
      <Field label='CSS Theme' name='theme' controlProps={{ as: 'select' }}>
        { partner.availableThemes.map((theme) => <option key={theme} value={theme}>{startCase(theme)}</option>) }
      </Field>
      <Field label='Funnel Start View' name='funnelStartView' controlProps={{ as: 'select' }}>
        <option value='housing-info'>housing-info</option>
        <option value='listing-info'>listing-info</option>
      </Field>
      <BooleanRadioField label='Cash Back Accrual' name='cashBackEnabled'/>
      <BooleanRadioField label='Show Funnel Intro' name='showFunnelIntro'/>
      <BooleanRadioField label='Forced Manual Review' name='forcedManualReview'/>
      <Field name='launchDate' label='Launch Date' controlProps={{ type: 'date' }}/>
      <Field name='contractRenewalDate' label='Contract Renewal Date' controlProps={{ type: 'date' }}/>
      <Field name='annualIncreasePercentage' label='Annual Increase Percentage'/>
      <BooleanRadioField label='Payments Page' name='paymentsPageEnabled'/>
      <BooleanRadioField label='Denial Delay' name='denialDelayEnabled'/>
      <Field label='Choose Plan Mode' name='choosePlanMode' controlProps={{ as: 'select' }}>
        <option value='slider'>Slider</option>
        <option value='input'>Input</option>
        <option value='skip'>Skip</option>
      </Field>
      <Field name='expirationDaysAfterApplicationCreated' label='Expiration Days After Application Created' controlProps={{ type: 'number' }}/>
      <Field name='expirationDaysAfterApproval' label='Expiration Days After Approval' controlProps={{ type: 'number' }}/>
      <Field name='expirationDaysAfterAuthorization' label='Expiration Days After Authorization' controlProps={{ type: 'number' }}/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>

    <ActivatePartnerIssuesModal
      expandable={activatePartnerIssuesModal}
      issues={issues}
      onHide={reset}
    />
  </div>;
}
