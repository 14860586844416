import React from 'react';
import { useParams } from 'react-router-dom';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { InternalBankAccount, Lockbox, useInvestorInternalBankAccounts } from '../../../api/queries/investors/useInternalBankAccounts';
import EntityID from '../../../components/EntityID';
import NotebookTable, { NotebookTableHeader } from '../../Search/NotebookTable';
import { Header } from '../../Layouts/Layout';

export default function InvestorsShowInternalBankAccounts () {
  const params = useParams<{ investorKey: string }>();
  const investorKey = params.investorKey as string;
  const {data: internalBankAccounts} = useInvestorInternalBankAccounts(investorKey);

  return (
    <div className='searchPage mb-5'>
      <Header>
        {investorKey}
      </Header>
      <ShowInvestorLayout
        investorKey={ investorKey }
        selectedTab='bank-accounts'
      >
        { internalBankAccounts
          ? <InternalBankAccountsTable internalBankAccounts={internalBankAccounts}/>
          : <div>Loading…</div> }
      </ShowInvestorLayout>
    </div>
  );
}

function InternalBankAccountsTable ({ internalBankAccounts }: { internalBankAccounts: Array<InternalBankAccount> }) {

  const formatLockbox = (lockbox: Lockbox) => {
    return <>
      {lockbox.name} <br/>
      {lockbox.address.addressLine1} {lockbox.address.addressLine2} <br/>
      {lockbox.address.city}, {lockbox.address.state} {lockbox.address.zip}
    </>
  }

  return <NotebookTable>
    <NotebookTableHeader>
      <th>Label</th>
      <th>Last Four</th>
      <th>Standard Lockbox</th>
      <th>Expedited Lockbox</th>
      <th>Collection Portfolios</th>
    </NotebookTableHeader>

    <tbody>
    {
      internalBankAccounts.map(internalBankAccount => (
        <tr key={internalBankAccount._id}>
          <td>{internalBankAccount.label}<br />
            <EntityID link={ false } id={ internalBankAccount._id } />
          </td>
          <td>{internalBankAccount.lastFour}</td>
          <td>{formatLockbox(internalBankAccount.mainLockbox)}</td>
          <td>{formatLockbox(internalBankAccount.expeditedLockbox)}</td>
          <td>{internalBankAccount.collectionPortfolios.join(', ')}</td>
        </tr>
      ))
    }
    </tbody>
  </NotebookTable>;
}
