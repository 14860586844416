import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from 'moment';
import { useExpandableState } from '../../utils';
import DollarField from '../Form/DollarField';
import Form from '../Form/Form';
import Field from '../Form/Field';
import { cleanDollarAmount } from '../../utils/form-utilities';

type Props = {
  modal: ReturnType<typeof useExpandableState>;
  onSubmit: (values: FieldValues) => void;
};

type FieldValues = {
  debt_type: string;
  remaining_balance: string;
  credit_limit: string;
  notes: string;
  date_effective: string;
  date_opened: string;
};

export const DEBT_TYPES = {
  MORTGAGE: 'mortgage',
  HELOC: 'heloc',
};

// @todo (maybe) display manual debts in the timeline?

export function ManualDebtModal ({ modal, onSubmit }: Props) {
  const schema = yup.object().shape({
    debt_type: yup.string().required('Please select a Debt Type'),
    remaining_balance: yup.string().required("Remaining Balance can't be empty"),
    credit_limit: yup.string().test('credit_limit', "Credit Limit can't be empty", (value, context) => !!value || context.parent.debt_type !== DEBT_TYPES.HELOC),
    notes: yup.string(),
    date_effective: yup.string().required("Date Effective can't be empty"),
    date_opened: yup.string(), // Allowed to be optional because it's not always available on mortgage statements
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      debt_type : '',
      remaining_balance: '',
      credit_limit: '',
      notes: '',
      date_effective: moment().format('Y-MM-DD'),
      date_opened: '',
    },
    resolver: yupResolver(schema)
  });
  const { formState: { isSubmitting, isDirty }, watch } = methods;
  const { debt_type } = watch();

  const handleSubmit = async (values: FieldValues) => {
    await onSubmit({
      ...values,
      remaining_balance: cleanDollarAmount(values.remaining_balance),
      credit_limit: cleanDollarAmount(values.credit_limit),
    });
    modal.hideExpand();
  };

  return (
    <Modal show={ modal.isExpanded } onHide={ modal.hideExpand }>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Debt Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field name='debt_type' label='Debt Type' controlProps={{ as: 'select' }}>
            <option value='' disabled>—</option>
            <option value={ DEBT_TYPES.MORTGAGE }>Mortgage/Home Equity Loan</option>
            <option value={ DEBT_TYPES.HELOC }>HELOC</option>
          </Field>

          <DollarField label='Remaining Balance' name='remaining_balance'/>

          { debt_type === DEBT_TYPES.HELOC ? <DollarField label='Credit Limit' name='credit_limit'/> : null }

          <Field name='date_opened' label='Date Opened' secondaryLabel='When this tradeline was opened/originated.' controlProps={{ type: 'date' }}/>
          <Field name='date_effective' label='Date Effective' secondaryLabel='The date these values were reported.' controlProps={{ type: 'date' }}/>

          <Field name='notes' label='Notes' controlProps={{ as: 'textarea' }} secondaryLabel='Any notes or comments about this debt, including source, is a refinance, etc.'/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={modal.hideExpand} disabled={isSubmitting}>Discard Changes</Button>
          <Button type='submit' variant='primary' disabled={!isDirty || isSubmitting}>Add Debt Record</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
