import React from 'react';
import { useParams } from 'react-router-dom';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { Portfolio, usePortfolios } from '../../../api/queries/investors/usePortfolios';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from '../../Search/NotebookTable';
import { Header } from '../../Layouts/Layout';

export default function InvestorsShowInfoPage () {
  const params = useParams<{ investorKey: string }>();
  const investorKey = params.investorKey as string;
  const portfolios = usePortfolios(investorKey);

  return (
    <div className='searchPage mb-5'>
      <Header>
        {investorKey}
      </Header>
      <ShowInvestorLayout
        investorKey={ investorKey }
        selectedTab='portfolios'
      >
        { portfolios.data ? <div>
          <PortfoliosTable portfolios={portfolios.data} investorKey={investorKey} />
        </div> : <div>Loading…</div> }
      </ShowInvestorLayout>
    </div>
  );
}

function PortfoliosTable ({ portfolios, investorKey }: { portfolios: Array<Portfolio>, investorKey: string }) {
  return <NotebookTable>
    <NotebookTableHeader>
      <th>Name</th>
    </NotebookTableHeader>
    <tbody>
      {
        portfolios.map(portfolio => (
          <NotebookTableRow key={portfolio.key} to={`/system/investors/${investorKey}/portfolios/${portfolio.key}`}>
            <td>{portfolio.name}</td>
          </NotebookTableRow>
        ))
      }
    </tbody>
  </NotebookTable>
}
