import React, { useEffect, useState } from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { Loan } from '../../api/queries/types';
import DollarField from '../Form/DollarField';
import { Decimal } from 'decimal.js';
import { formatDollars } from '../../utils';
import moment from 'moment';
import InlineButton from '../InlineButton';
import useBalloonPayment from '../../api/queries/loans/useBalloonPayment';
import useMaxPayments from '../../api/queries/loans/useMaxPayments';
import { cleanDollarAmount } from '../../utils/form-utilities';

type FieldValues = {
  firstPaymentDate: string;
  paymentAmountDollars: string;
  totalPayments: string;
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  loan: Loan;
};

export default function AddPaymentPlanModal (props: Props) {
  const { expand, onSubmit, loan } = props;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/payment/plan`,
    method: 'POST',
  });

  async function handleSubmit (values: FieldValues) {
    await callAPI({ data: {
      firstPaymentDate: values.firstPaymentDate,
      paymentAmountCents: new Decimal(cleanDollarAmount(values.paymentAmountDollars)).times(100).toNumber(),
      totalPayments: Number(values.totalPayments),
    }});
    onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    firstPaymentDate: yup.string().required('Please select a date')
      .test('is-future', 'Date must be in the future', value => {
        return moment(value).isSameOrAfter(moment().startOf('day'));
      })
      .test('is-valid', 'Date cannot be 29th, 30th, or 31st', value => {
        return ![29, 30, 31].includes(moment(value).date());
      }),
    paymentAmountDollars: yup.string().required('Please enter an amount')
      .test('is-positive', 'Amount must be positive', value => {
        return Number(cleanDollarAmount(value)) > 0;
      }),
    totalPayments: yup.string().required('Please enter a number of payments')
      .test('is-positive', 'Number of payments must be greater than 1', value => {
        return Number(value) > 1;
      }),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      firstPaymentDate: '',
      paymentAmountDollars: '',
      totalPayments: '',
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting }, reset, watch, setValue, trigger } = methods;
  const [paymentAmountDollars, totalPayments, firstPaymentDate] = watch(['paymentAmountDollars', 'totalPayments', 'firstPaymentDate']);
  const paymentAmountCents = new Decimal(cleanDollarAmount(paymentAmountDollars) || 0).times(100).toNumber();

  const { data: balloonPaymentResponse, isLoading: isLoadingBalloonPayment } = useBalloonPayment(loan._id, paymentAmountCents.toString(), totalPayments, firstPaymentDate);
  const { data: maxNumberOfPaymentsResponse, isLoading: isLoadingMaxPayments } = useMaxPayments(loan._id, paymentAmountCents.toString(), firstPaymentDate);
  const [balloonPaymentCents, setBalloonPaymentCents] = useState<number | undefined>(balloonPaymentResponse?.balloonPayment);

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  useEffect(() => {
    if (balloonPaymentResponse?.balloonPayment && balloonPaymentResponse.balloonPayment >= 0) {
      setBalloonPaymentCents(balloonPaymentResponse.balloonPayment);
    }
  }, [balloonPaymentResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (maxNumberOfPaymentsResponse?.maxNumberOfPayments && maxNumberOfPaymentsResponse.maxNumberOfPayments < Number(totalPayments)) {
      setValue('totalPayments', maxNumberOfPaymentsResponse.maxNumberOfPayments.toString());
    }
  }, [maxNumberOfPaymentsResponse, totalPayments]); // eslint-disable-line react-hooks/exhaustive-deps

  const onResetToMax = () => {
    setValue('totalPayments', maxNumberOfPaymentsResponse?.maxNumberOfPayments?.toString() || '100');
  }

  const nextPaymentDate = firstPaymentDate ? moment(firstPaymentDate).format('MMMM D, YYYY') : null;
  const lastPaymentDate = firstPaymentDate && totalPayments ? moment(firstPaymentDate).add(Number(totalPayments) - 1, 'months').format('MMMM D, YYYY') : null;

  const onAddPaymentPlanClick = async () => {
    if (await trigger()) {
      setShowConfirmation(true);
    }
  };

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide}>
      <Modal.Header>
        <Modal.Title>
          Payment plan
        </Modal.Title>
      </Modal.Header>
      <Form {...methods} onSubmit={handleSubmit}>
        { showConfirmation ?
          <div className={`p-3`}>
            Before confirming, please review all details below are correct.
          </div>
        :
          <div className='p-3'>
            <Field name='firstPaymentDate' label='First payment date' controlProps={{ type: 'date' }}/>
            <DollarField name='paymentAmountDollars' label='Payment amount'/>
            <Field
              name='totalPayments'
              label={<div className='d-flex'>
                <div># of payments</div>
                <InlineButton className='ml-auto font-weight-normal' onClick={onResetToMax}>
                  Reset to max
                </InlineButton>
              </div>}
              controlProps={{ type: 'number' }}
            />

            <SubmitError/>
          </div>
        }
          <Container className='border-top border-bottom p-3 bg-light'>
            <Row className='mb-3'>
              <Col><strong>Summary</strong></Col>
            </Row>
            <Row>
              <Col>Payment frequency</Col>
              <Col className='text-right'>Monthly</Col>
            </Row>
            <Row>
              <Col>Monthly payment amount</Col>
              <Col className='text-right'>{ formatDollars(paymentAmountDollars) }</Col>
            </Row>
            <Row>
              <Col># of payments</Col>
              <Col className='text-right'>{ totalPayments }</Col>
            </Row>
            <Row>
              <Col>Next payment date</Col>
              <Col className='text-right'>{ nextPaymentDate }</Col>
            </Row>
            <Row>
              <Col>Last payment date</Col>
              <Col className='text-right'>{ lastPaymentDate }</Col>
            </Row>
            <Row>
              <Col>Last payment amount</Col>
              <Col className='text-right'>{ formatDollars(balloonPaymentCents, { cents: true }) }</Col>
            </Row>
          </Container>
        { showConfirmation ?
          <div className='p-3 d-flex'>
            <Button variant='secondary'  className='w-50' onClick={() => setShowConfirmation(false)}>Cancel</Button>
            <div className='w-50 ml-2'>
              <ButtonWithSpinner  block type="submit" loading={isSubmitting}>
                Confirm
              </ButtonWithSpinner>
            </div>
          </div>
        :
          <div className='p-3'>
            <ButtonWithSpinner block loading={isLoadingBalloonPayment || isLoadingMaxPayments} onClick={onAddPaymentPlanClick}>
              Add payment plan
            </ButtonWithSpinner>
          </div>
        }
      </Form>
    </Modal>
  );
}
