import React from 'react';
import useState from '../../../api/queries/states/useState';
import { useParams } from 'react-router-dom';
import StateShow from '../../../components/System/States/StateShow';
import { Header } from '../../Layouts/Layout';

export default function StateShowPage () {
  const { stateId } = useParams<{ stateId: string }>();

  const statesRequest = useState(stateId);
  if (!statesRequest.data) {
    return null;
  }

  return (
    <div className='searchPage mb-5'>
      <Header>
        {statesRequest.data.name}
      </Header>
      <StateShow state={statesRequest.data}/>
    </div>
  );
}
