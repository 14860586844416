import useMutation from '../../shared/useMutation';

type Payload = {
  notes: string;
  reason: string;
};

export default function useMutateReopenLoanApplication (loanApplicationId: string) {
  return useMutation<Payload, any>('PUT', `/notebook/loan-applications/${loanApplicationId}/reopen`);
}
