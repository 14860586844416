import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import DollarField from '../Form/DollarField';
import SubmitError from '../Form/SubmitError';
import { Button, Modal } from 'react-bootstrap';
import { isDateOnlyWithinReasonableBounds } from '../../utils/datetime';
import { postAPIEndpoint } from '../../utils/useAdminAPIData';
import Decimal from 'decimal.js';
import useExpandableState from '../../utils/useExpandableState';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { cleanDollarAmount } from '../../utils/form-utilities';

function TransactionTypeHelpText (props: { type: string }) {
  const mapping = {
    borrower_ach_debit_repayment: 'ACH direct debit for Notable to pull funds from Borrower account.',
    borrower_electronic_repayment: 'Repayment from Borrower (or on their behalf) by EFT.',
    borrower_check_repayment: 'Repayment from Borrower (or on their behalf) by Check.',
    ach_disbursement: 'ACH direct deposit disbursement from Notable to Borrower account.',
    refund_to_borrower: 'ACH direct deposit refund from Notable to Borrower account, due to overpayment.',
    collection_fee: 'Accounting transaction to record a collection fee.',
    collection_fee_reversal: 'Accounting transaction to record the reversal/refund of a collection fee.',
    retroactive_principal_overpayment_allocation: 'Accounting transaction to reallocate a post-repayment card refund (or equivalent) to overpayments to facilitate a borrower refund.',
    retroactive_interest_overpayment_allocation: 'Accounting transaction to reallocate collected interest to overpayments to facilitate a borrower refund.',
    retroactive_fee_overpayment_allocation: 'Accounting transaction to reallocate collected fees to overpayments to facilitate a borrower refund.',
  }
  return (
    <>
      { mapping[props.type as keyof typeof mapping] }
    </>
  );
}

type CreateTransactionModalContentsProps = {
  modal: ReturnType<typeof useExpandableState>;
  loan: { _id: string, slug: string };
  onSubmit: (v: any) => Promise<void>;
};

type FieldValues = {
  type: string;
  amount: string;
  effective_date: string;
  notes: string;
  lender_writeoff_reason: string;
  external_id: string;
};

export function CreateTransactionModalContents ({ modal, loan, onSubmit }: CreateTransactionModalContentsProps) {
  const schema = yup.object({
    type: yup.string().required('This field is required'),
    effective_date: yup.string().test('effective_date', 'Please enter a valid date within 10 years.', value => !!value && isDateOnlyWithinReasonableBounds(value)),
    amount: yup.string().test('amount', 'Must be a positive, non-zero dollar value.', value => !!value && new Decimal(cleanDollarAmount(value)).greaterThan(0)),
    external_id: yup.string(),
    notes: yup.string(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      type: '',
      effective_date: '',
      amount: '',
      external_id: '',
      notes: '',
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, watch } = methods;
  const { type } = watch();

  const handleSubmit = async (form: FieldValues) => {
    await onSubmit({
      ...form,
      amount: (new Decimal(cleanDollarAmount(form.amount))).times(100).toNumber(),
    });
    modal.hideExpand();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Add accounting transaction for { loan.slug }</Modal.Title>
      </Modal.Header>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Body>
          <Field name='type' label='Type' controlProps={{ as: 'select' }} secondaryLabel={<TransactionTypeHelpText type={type} />}>
            <option value=''>--</option>
            <option value='collection_fee'>Collection Fee</option>
            <option value='collection_fee_reversal'>Collection Fee Reversal</option>
            <option value='late_fee'>Late Fee</option>
            <option value='unclaimed_funds'>Record Unclaimed Funds</option>
            <option value='unclaimed_funds_reversal'>Record Unclaimed Funds Reversal</option>
            <option value='retroactive_principal_overpayment_allocation'>Retroactive Principal Overpayment Allocation</option>
            <option value='retroactive_interest_overpayment_allocation'>Retroactive Interest Overpayment Allocation</option>
            <option value='retroactive_fee_overpayment_allocation'>Retroactive Fee Overpayment Allocation</option>
          </Field>

          <Field name='effective_date' label='Effective Date' controlProps={{ type: 'date' }}/>
          <DollarField name='amount' label='Amount'/>

          <Field name='external_id' label='External ID' secondaryLabel='An optional external reference number (eg Check #, transfer ID)'/>

          <hr />

          <Field name='notes' label='Internal Notes' secondaryLabel='Any notes or comments about this update.' controlProps={{ as: 'textarea' }}/>

          <SubmitError/>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant   = 'secondary'
            onClick   = { modal.hideExpand }
            disabled  = { isSubmitting }
          >
            Discard Changes
          </Button>
          <ButtonWithSpinner
            variant   = 'primary'
            type      = 'submit'
            disabled  = { !isDirty }
            loading   = { isSubmitting }
          >
            Add Transaction
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </>
  );
}

type Props = {
  loan: { _id: string, slug: string };
  onChange?: () => void;
  modal: ReturnType<typeof useExpandableState>;
};

export default function CreateTransactionModal (props: Props) {
  async function onSubmit (data: any) {
    await postAPIEndpoint(`/notebook/loans/${ props.loan._id }/ledger/transactions`, data);
    props.onChange && props.onChange();
  }
  return (
    <Modal show={ props.modal.isExpanded } onHide={ props.modal.hideExpand }>
      {
        props.modal.isExpanded
        && <CreateTransactionModalContents
          onSubmit={ onSubmit }
          {...props}
        />
      }
    </Modal>
  );
}
