import useQuery from '../../shared/useQuery';

export type OfferedPlanSummary = {
  aprBps: number;
  originationFeeCents: number;
  interestRateBps: number;
};

export default function useOfferedPlanSummary (loanApplicationId: string, loanAmountCents: number) {
  return useQuery<OfferedPlanSummary>(`/notebook/loan-applications/${ loanApplicationId }/offered-plan-summary?loanAmountCents=${loanAmountCents}`);
}
