import React, { useEffect, useState } from 'react';
import useExpandableState from '../../utils/useExpandableState';
import GenericModal from './GenericModal';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import DollarField from '../Form/DollarField';
import SubmitError from '../Form/SubmitError';
import { Button, Col, Container, Row, Table, FormText, FormLabel } from 'react-bootstrap';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { Loan } from '../BorrowerTabs/ServicingTab/Activity';
import useAccountingBalanceBreakdown, { AccountingBalancesResponse } from '../../api/queries/loans/useAccountingBalanceBreakdown';
import { isDateOnlyWithinReasonableBounds } from '../../utils/datetime';
import Field from '../Form/Field';
import { formatCents } from '../../utils';
import CardCheckbox from '../Form/CardCheckbox';
import useAdminAPICall from '../../utils/useAdminAPICall';
import Decimal from 'decimal.js';
import { cleanDollarAmount } from '../../utils/form-utilities';

type FieldValues = {
  debt_forgiveness: boolean;
  accounting_date: string;
  effective_date: string;
  notes: string;
  writeoff_reason: string;
  notable_investor_interest_write_off_dollars: string;
  notable_investor_principal_write_off_dollars: string;
  notable_interest_write_off_dollars: string;
  investor_interest_write_off_dollars: string;
  investor_principal_write_off_dollars: string;
  notable_fee_write_off_dollars: string;
  notable_overpayments_write_off_dollars: string;
};

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  loan: Loan;
  onSubmit: () => void;
};

function WriteOffModalContent (props: Props) {
  const { expandable, loan, onSubmit } = props;

  const balanceBreakdown = useAccountingBalanceBreakdown(loan._id);
  const [balancesAfter, setBalancesAfter] = useState<Partial<AccountingBalancesResponse['balances']>>({} as any);
  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loan._id}/accounting/write-offs`,
    method: 'POST',
  });

  const handleSubmit = async (form: FieldValues) => {
    await callAPI({ data: {
      ...form,
      notable_investor_interest_write_off_dollars: cleanDollarAmount(form.notable_investor_interest_write_off_dollars) || undefined,
      notable_investor_principal_write_off_dollars: cleanDollarAmount(form.notable_investor_principal_write_off_dollars) || undefined,
      notable_interest_write_off_dollars: cleanDollarAmount(form.notable_interest_write_off_dollars) || undefined,
      investor_interest_write_off_dollars: cleanDollarAmount(form.investor_interest_write_off_dollars) || undefined,
      investor_principal_write_off_dollars: cleanDollarAmount(form.investor_principal_write_off_dollars) || undefined,
      notable_fee_write_off_dollars: cleanDollarAmount(form.notable_fee_write_off_dollars) || undefined,
      notable_overpayments_write_off_dollars: cleanDollarAmount(form.notable_overpayments_write_off_dollars) || undefined,
    }})
    onSubmit();
    expandable.hideExpand();
  };

  const schema = yup.object().shape({
    accounting_date: yup.string().test('accounting_date', 'Please enter a valid date within 10 years.', value => !!value && isDateOnlyWithinReasonableBounds(value)),
    effective_date: yup.string().test('effective_date', 'Please enter a valid date within 10 years.', value => !value || isDateOnlyWithinReasonableBounds(value)),
    debt_forgiveness: yup.boolean(),
    notes: yup.string(),
    writeoff_reason: yup.string().required(),
    notable_investor_interest_write_off_dollars: yup.string().test('notable_investor_interest_write_off_dollars', 'Must be a positive, non-zero dollar value', value => !value || parseFloat(value) > -10),
    notable_investor_principal_write_off_dollars: yup.string().test('notable_investor_principal_write_off_dollars', 'Must be a positive, non-zero dollar value', value => !value || parseFloat(value) > -10),
    notable_interest_write_off_dollars: yup.string().test('notable_interest_write_off_dollars', 'Must be a positive, non-zero dollar value', value => !value || parseFloat(value) > -10),
    investor_principal_write_off_dollars: yup.string().test('investor_principal_write_off_dollars', 'Must be a positive, non-zero dollar value', value => !value || parseFloat(value) > -10),
    investor_interest_write_off_dollars: yup.string().test('investor_interest_write_off_dollars', 'Must be a positive, non-zero dollar value', value => !value || parseFloat(value) > -10),
    notable_fee_write_off_dollars: yup.string().test('notable_nsf_fee_write_off_dollars', 'Must be a positive, non-zero dollar value', value => !value || parseFloat(value) > -10),
    notable_overpayments_write_off_dollars: yup.string().test('notable_overpayments_write_off_dollars', 'Must be a positive, non-zero dollar value', value => !value || parseFloat(value) > -10),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      debt_forgiveness: false,
      accounting_date: '',
      effective_date: '',
      notes: '',
      writeoff_reason: '',
      notable_investor_interest_write_off_dollars: '',
      notable_investor_principal_write_off_dollars: '',
      notable_interest_write_off_dollars: '',
      notable_fee_write_off_dollars: '',
      investor_interest_write_off_dollars: '',
      notable_overpayments_write_off_dollars: '',
    },
    resolver: yupResolver(schema),
  });
  const { formState } = methods;
  const { isSubmitting } = formState;

  useEffect(() => {
    if (balanceBreakdown.data?.balances) {
      setBalancesAfter(() => balanceBreakdown.data!.balances);
    }
  }, [balanceBreakdown.data, balanceBreakdown.data?.balances]);
  function refreshAfterBalances () {
    if (!balanceBreakdown.data) { return; }
    const values = methods.getValues();
    const principal_write_off = (new Decimal(cleanDollarAmount(values.notable_investor_principal_write_off_dollars) || 0)).plus(cleanDollarAmount(values.investor_principal_write_off_dollars) || 0).times(100).toNumber();
    const investor_interest_write_off = (new Decimal(cleanDollarAmount(values.notable_investor_interest_write_off_dollars) || 0)).plus(cleanDollarAmount(values.investor_interest_write_off_dollars) || 0).times(100).toNumber()
    const notable_interest_write_off = (new Decimal(cleanDollarAmount(values.notable_interest_write_off_dollars) || 0)).times(100).toNumber();
    const notable_fee_write_off = (new Decimal(cleanDollarAmount(values.notable_fee_write_off_dollars) || 0)).times(100).toNumber();
    const after = {
      principal: balanceBreakdown.data!.balances.principal - principal_write_off,
      investor_uncollected_interest: balanceBreakdown.data!.balances.investor_uncollected_interest - investor_interest_write_off,
      notable_uncollected_interest: balanceBreakdown.data!.balances.notable_uncollected_interest - notable_interest_write_off,
      fees_receivable: balanceBreakdown.data!.balances.fees_receivable - notable_fee_write_off,
    };
    setBalancesAfter(after);
  }

  if (!balanceBreakdown.data) {
    return <>Loading…</>
  }

  const { balances, valid_through_date } = balanceBreakdown.data;

  return (
    <Form {...methods} onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col>
            <Field name='accounting_date' label='Accounting Date' controlProps={{ type: 'date' }}/>
          </Col>
          <Col>
            <Field name='writeoff_reason' label='Reason' controlProps={{ as: 'select' }}>
              <option value="">—</option>
              <option value="disbursement_error">Disbursement Error</option>
              <option value="borrower_underpaid">Borrower Underpaid</option>
              <option value="borrower_overpaid">Borrower Overpaid</option>
              <option value="borrower_overpaid_max5dollars">Borrower Overpaid (max $5)</option>
            </Field>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <Field name='effective_date' label='Effective Date (optional)' secondaryLabel='By default, the transaction will be effective the Accounting Date. Use this Effective Date to set it to a different date if needed.' controlProps={{ type: 'date' }}/>
          </Col>
          <Col>
            <FormLabel>Reportable</FormLabel>
            <CardCheckbox name='debt_forgiveness' label='Debt Forgiveness' />
            <FormText className="text-muted">Record the write-off as reportable debt forgiveness instead of a regular write-off.</FormText>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>
                    <small>As of { valid_through_date }</small>
                  </th>
                  <th>
                    Current
                  </th>
                  <th>Notable Write-Off</th>
                  <th>Investor Write-Off</th>
                  <th>After</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Principal</th>
                  <td className="text-right">
                    { formatCents(balances.principal, '—') }
                    <br /><small className='text-muted'>({ formatCents(balances.investor_principal) } sold)</small>
                  </td>
                  <td><DollarField onBlur={ refreshAfterBalances } disabled={ balances.principal === 0 } name='notable_investor_principal_write_off_dollars' label=''/></td>
                  <td><DollarField onBlur={ refreshAfterBalances } disabled={ balances.investor_principal === 0 } name='investor_principal_write_off_dollars' label=''/></td>
                  <td className={ `text-right ${ afterBalanceColor(balancesAfter.principal) }` }>{ formatCents(balancesAfter.principal, '—') }</td>
                </tr>
                <tr></tr>
                <tr>
                  <th>Investor Interest</th>
                  <td className="text-right">{ formatCents(balances.investor_uncollected_interest, '—') }</td>
                  <td><DollarField onBlur={ refreshAfterBalances } disabled={ balances.investor_uncollected_interest === 0 } name='notable_investor_interest_write_off_dollars' label=''/></td>
                  <td><DollarField onBlur={ refreshAfterBalances } disabled={ balances.investor_uncollected_interest === 0 } name='investor_interest_write_off_dollars' label=''/></td>
                  <td className={ `text-right ${ afterBalanceColor(balancesAfter.investor_uncollected_interest) }` }>{ formatCents(balancesAfter.investor_uncollected_interest, '—') }</td>
                </tr>
                <tr>
                  <th>Notable Interest</th>
                  <td className="text-right">{ formatCents(balances.notable_uncollected_interest, '—') }</td>
                  <td><DollarField onBlur={ refreshAfterBalances } disabled={ balances.notable_uncollected_interest === 0 } name='notable_interest_write_off_dollars' label=''/></td>
                  <td></td>
                  <td className={ `text-right ${ afterBalanceColor(balancesAfter.notable_uncollected_interest) }` }>{ formatCents(balancesAfter.notable_uncollected_interest, '—') }</td>
                </tr>
                <tr>
                  <th>Fees</th>
                  <td className="text-right">{ formatCents(balances.fees_receivable, '—') }</td>
                  <td><DollarField onBlur={ refreshAfterBalances } disabled={ balances.fees_receivable === 0 } name='notable_fee_write_off_dollars' label=''/></td>
                  <td></td>
                  <td className={ `text-right ${ afterBalanceColor(balancesAfter.fees_receivable) }` }>{ formatCents(balancesAfter.fees_receivable, '—') }</td>
                </tr>
                <tr>
                  <th>Overpayment</th>
                  <td className="text-right">{ formatCents(balances.overpayments, '—') }</td>
                  <td><DollarField onBlur={ refreshAfterBalances } disabled={ balances.overpayments === 0 } name='notable_overpayments_write_off_dollars' label=''/></td>
                  <td></td>
                  <td className={ `text-right ${ afterBalanceColor(balancesAfter.overpayments) }` }>{ formatCents(balancesAfter.overpayments, '—') }</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name='notes' label='Notes (optional)' placeholder='Enter notes' controlProps={{ as: 'textarea' }}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitError/>
          </Col>
        </Row>
      </Container>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={expandable.hideExpand} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" loading={isSubmitting}>
          Create Write-Offs
        </ButtonWithSpinner>
      </div>
    </Form>
  )
}

function afterBalanceColor (val: number | undefined) {
  if (val !== undefined) {
    if (val < 0) {
      return 'text-danger';
    }
    if (val === 0) {
      return 'text-success';
    }
  }
  return '';
}

export default function CreateWriteOffModal (props: Props) {
  const { expandable } = props;

  return (
    <GenericModal expandable={expandable} title="Record Write-Off" size="xl">
      {
        expandable.isExpanded && <WriteOffModalContent {...props} />
      }
    </GenericModal>
  );
}
