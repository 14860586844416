import React from 'react';
import GenericModal from '../GenericModal';
import useExpandableState from '../../../utils/useExpandableState';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Decimal from 'decimal.js';
import { RefundDetail } from '../../../api/queries/loans/useRefundDetail';
import RefundRequestForm, { RefundRequestFormFields } from './RefundRequestForm';
import { cleanDollarAmount } from '../../../utils/form-utilities';
import humanize from 'humanize';

type Props = {
  modal: ReturnType<typeof useExpandableState>;
  loanId: string;
  refund: RefundDetail;
  onSubmit: () => void;
};

export function ModifyRefundRequestModal (props: Props) {
  const { loanId, refund, modal, onSubmit } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loans/${loanId}/refunds/${refund._id}/update`,
    method: 'PUT',
  });

  const defaultValues: RefundRequestFormFields = {
    amount: refund.amount ? humanize.numberFormat(refund.amount / 100, 2) : '',
    effective_date: refund.effective_date,
    purchase_method_id: refund.destinationAccount._id,
    type: 'ach', // TODO: refactor when allowing for refunds other than ACH
    notes: '',
  };

  const closeModal = () => {
    modal.hideExpand();
  }

  const handleSubmit = async (form: RefundRequestFormFields) => {
    await callAPI({ data: {
      ...form,
      amount: new Decimal(cleanDollarAmount(form.amount)).times(100).toNumber(),
    }})
    onSubmit();
    closeModal();
  };

  return (
    <GenericModal expandable={ modal } title='Refund Details' onClose={ closeModal }>
      <RefundRequestForm
        loanId={ loanId }
        handleSubmit={ handleSubmit }
        handleCancel={ closeModal }
        defaultValues={ defaultValues }
      />
    </GenericModal>
  );
};
