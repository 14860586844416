import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { useInvestorReports } from '../../../api/queries/investors/useReports';
import { Pagination } from 'react-bootstrap';
import { formatDateWithTimeLocalTimezone } from '../../../utils/formatDate';
import NotebookTable, { NotebookTableRow } from '../../Search/NotebookTable';
import { Header } from '../../Layouts/Layout';

export default function InvestorsShowReportsPage () {
  const params = useParams<{ investorKey: string }>();
  const investorKey = params.investorKey as string;

  return (
    <div className='searchPage mb-5'>
      <Header>
        {investorKey}
      </Header>
      <ShowInvestorLayout
        investorKey={ investorKey }
        selectedTab='reports'
      >
        <InvestorsShowReports/>
      </ShowInvestorLayout>
    </div>
  );
}

function InvestorsShowReports () {
  const params = useParams<{ investorKey: string }>();
  const [queryParams] = useSearchParams({ page: '1' });
  const navigate = useNavigate();
  const currentPage = parseInt(queryParams.get('page') as string, 10) || 1;
  const investorKey = params.investorKey as string;
  const reports = useInvestorReports(investorKey, currentPage);

  const nextPage = currentPage + 1;
  const prevPage = currentPage === 1 ? null : currentPage - 1;

  return (
    <div>
      <div className='px-4'>
        <Pagination>
          <Pagination.Prev onClick={() => navigate(`/system/investors/${investorKey}/reports?page=${ prevPage }`)} disabled={!prevPage} />
          <Pagination.Next onClick={() => navigate(`/system/investors/${investorKey}/reports?page=${ nextPage }`)} disabled={!nextPage} />
        </Pagination>
      </div>
      <NotebookTable>
        <tbody>
        {
          reports.data?.map(report => (
            <NotebookTableRow key={ report._id } to={`/system/investors/${investorKey}/reports/${report._id}`}>
              <td>{ report.report_date }</td>
              <td>{ report.type }</td>
              <td>{ report.isDelivered ? 'Delivered' : 'Undelivered' }</td>
              <td>{ report.deliveredAt ? formatDateWithTimeLocalTimezone(report.deliveredAt) : null }</td>
            </NotebookTableRow>
          ))
        }
        </tbody>
      </NotebookTable>
    </div>
  );
}
