import React from 'react';
import { usePartners } from '../../../api/queries/partners/usePartners';
import Simulator from './Simulator';
import { Header } from '../../Layouts/Layout';

export default function PartnersPage () {
  const partnersRequest = usePartners();

  if (!partnersRequest.data) {
    return null;
  }

  return (
    <div className='searchPage mb-5'>
      <Header>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white m-0 p-0 align-items-center">
            <li className="breadcrumb-item">System</li>
            <li className="breadcrumb-item active" aria-current="page">Simulator</li>
          </ol>
        </nav>
      </Header>
      <div className='px-4'>
        <Simulator partners={partnersRequest.data.filter(p => p.active)}/>
      </div>
    </div>
  );
}
