import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { InputGroup, FormControl, Form } from 'react-bootstrap';
import querystring from 'qs';
import InlineError from '../../components/InlineError';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { useForm } from 'react-hook-form';
import formatDollars from '../../utils/formatDollars';
import { trim } from 'lodash';
import './Search.css';
import formatPhone from '../../utils/formatPhone';
import moment from 'moment';
import Close from '../../images/Close';
import SearchImage from '../../images/Search';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from './NotebookTable';
import { Header } from '../Layouts/Layout';
import SortableTh from '../../components/SortableTh';
import Pagination from '../../components/Pagination';

export const SEARCH_PAGE_SIZE = 200;

type LoanApplicationsSearchResponse = {
  loanApplications: Array<LoanApplication>;
  total: number;
}

type LoanApplication = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  partnerShortName: string;
  agentDisplayName: string;
  _id: string;
  principal: number;
  loanSlug: string | null;
  status: string;
  createdAt: string;
};

type NotebookSearch = {
  searchString: string;
  firstSearchedAt: Date;
  lastSearchedAt: Date;
}

function cleanValue(val: string | null) {
  return !val ? '--' : val;
}

export default function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedParams = querystring.parse(location.search, { ignoreQueryPrefix: true });

  const [searchTerms, setSearchTerms] = useState('');
  const [searchResults, setSearchResults] = useState<Array<LoanApplication>>([]);
  const [maxPages, setMaxPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [recentSearches, setRecentSearches] = useState<Array<NotebookSearch>>([]);
  const [showRecentSearches, setShowRecentSearches] = useState(false);

  const { register, handleSubmit, setValue, resetField, setFocus, formState: { errors } } = useForm<{ searchText: string }>();
  const { callAPI, loadError, loadPending, loadComplete } = useAdminAPICall<LoanApplicationsSearchResponse>({
    endpoint: `/notebook/loan-applications/search`,
  });
  const { callAPI: getRecentSearches } = useAdminAPICall<Array<NotebookSearch>>({
    endpoint: '/notebook/loan-applications/recent-searches',
  });

  useEffect(() => { document.title = 'Home | Notebook' }, []);

  // eslint react-hooks/exhaustive-deps is disabled bc the effect should only fire when the url updates
  useEffect(() => {
    let canceled = false;
    const page = parsedParams.page ? parseInt(parsedParams.page as string) : 1;
    const text = parsedParams.searchText ? parsedParams.searchText as string : '';
    const sortBy = parsedParams.sortBy ? parsedParams.sortBy as string : undefined;
    const sortDirection = parsedParams.sortDirection ? parsedParams.sortDirection as string : undefined;
    const config = {
      params: {
        searchText: text,
        sortBy,
        sortDirection,
        page,
        perPage: SEARCH_PAGE_SIZE,
      }
    };

    if (text) {
      callAPI(config).then((resp) => {
        if (!canceled) {
          setSearchResults(resp.data.loanApplications);
          setMaxPages(Math.ceil(resp.data.total / SEARCH_PAGE_SIZE));
        }
      });

    } else {
      setSearchResults([])
    }

    setValue('searchText', text);
    setSearchTerms(text as string);
    setActivePage(page);

    return () => { canceled = true }
  }, [parsedParams.searchText, parsedParams.page, parsedParams.sortBy, parsedParams.sortDirection]); // eslint-disable-line react-hooks/exhaustive-deps

  const setSearchUrl = (searchText = '', page = 1, sortBy = '', sortDirection = '', clear = false) => {
    setShowRecentSearches(false);

    if (searchText) {
      const encodedSearchText = encodeURIComponent(searchText);
      const pageParam = page > 1 ? '&page=' + page : '';
      const sortByParam = sortBy ? `&sortBy=${sortBy}` : '';
      const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '';
      if (location.search !== `?searchText=${encodedSearchText}${pageParam}${sortByParam}${sortDirectionParam}`) {
        navigate(`?searchText=${encodedSearchText}${pageParam}${sortByParam}${sortDirectionParam}`);
      }
    } else if (clear) {
      navigate(``)
    }
  }

  const handlePagination = (newActivePage: number) => {
    const sortBy = parsedParams.sortBy ? parsedParams.sortBy as string : undefined;
    const sortDirection = parsedParams.sortDirection ? parsedParams.sortDirection as string : undefined;
    setSearchUrl(searchTerms, newActivePage, sortBy, sortDirection);
  }

  const clearSearch = async () => {
    resetField('searchText');
    setSearchUrl('', 1, '', '', true);
    setSearchResults([]);
    setActivePage(1);
    setSearchTerms('');
    setFocus('searchText');
    await fetchAndDisplayRecentSearches(true);
  }

  const fetchAndDisplayRecentSearches = async (forceDisplay?: boolean) => {
    // Do not display recent searches if there are already search results
    if (!searchResults.length || forceDisplay) {
      setShowRecentSearches(true);
      const { data: searches } = await getRecentSearches();
      setRecentSearches(searches);
    }
  }

  // Upon loading the page, don't show recent searches if there is a search term in the URL
  // or if search bar is not empty
  const handleFocusOnSearchBar = async (event: any) => {
    if (!event.target.value && !parsedParams.searchText) {
      await fetchAndDisplayRecentSearches();
    }
  }

  const chooseRecentSearch = (searchString: string) => {
    setValue('searchText', searchString);
    setSearchUrl(searchString, 1, '', '', true);
  }

  const handleInputChange = async (event: any) => {
    setValue('searchText', event.target.value);
  }

  return (
    <div className='searchPage mb-5'>
      <Header>
        <form onSubmit={handleSubmit(data => setSearchUrl(data.searchText, 1, '', '', true))}>
          <Form.Group
            controlId="searchBar"
            className='mb-0'
          >
            <InputGroup>
              <InputGroup.Prepend className='align-items-center'>
                <button className='searchSubmit' type='submit'>
                  <SearchImage />
                </button>
              </InputGroup.Prepend>
              <FormControl
                placeholder='Search Notebook...'
                autoFocus
                {...register('searchText', { required: true })}
                className='searchInput'
                onFocus={handleFocusOnSearchBar}
                onChange={handleInputChange}
              />
              { searchTerms &&
                <InputGroup.Append className='align-items-center cursor-pointer' onClick={clearSearch}>
                  <Close />
                </InputGroup.Append>
              }
            </InputGroup>

            {
              !!errors.searchText && <Form.Text className="text-danger">
                Please enter at least one term for search
              </Form.Text>
            }
          </Form.Group>
        </form>
      </Header>

      { showRecentSearches && recentSearches.length > 0 &&
        <div className='mt-4 mb-4 pl-4'>
          <div className='mb-2 font-weight-strong'>Recent searches</div>
          <div>
            {
              recentSearches.map(search => (
                <div
                  className='recentSearchesRow'
                  key={ search.searchString }
                  onClick={ () => chooseRecentSearch(search.searchString) }
                >
                  <SearchImage pathProps={{ fill: '#6c757d' }} /><span className='recentSearchesSpan'>{ search.searchString }</span>
                </div>)
              )
            }
          </div>
        </div>
      }
      <div>
        {loadPending && <div className='mt-2 pl-4 text-muted'>Loading...</div> }
        {loadError && <InlineError className='pl-4'>Error loading search results. Please clear your search and try again.</InlineError>}
        {(loadComplete && searchTerms) &&
          <>
            {searchResults.length === 0 ?
              <div data-emptyresults className='mt-2 pl-4 text-muted'>
                {parsedParams.page ? 'No more results for ' : 'No results for: '}
                "{searchTerms}"
              </div>
            :
              <>
                <SearchTable searchResults={searchResults} />
                <div className='d-flex'>
                  <Pagination
                    page={activePage}
                    maxPages={maxPages}
                    onPageChange={handlePagination}
                    className='ml-auto mr-4'
                  />
                </div>
              </>
            }
          </>
        }
      </div>
    </div>
  );
}

function SearchTable ({ searchResults }: { searchResults: Array<LoanApplication> }) {
  return (
    <NotebookTable>
      <NotebookTableHeader>
        <SortableTh sortBy='last_name'>Name</SortableTh>
        <SortableTh sortBy='phone'>Phone</SortableTh>
        <SortableTh sortBy='email'>Email</SortableTh>
        <SortableTh sortBy='principal'>Amount</SortableTh>
        <SortableTh sortBy='status'>Status</SortableTh>
        <SortableTh sortBy='Agent.last_name'>Agent</SortableTh>
        <SortableTh sortBy='Partner.short_name'>Partner</SortableTh>
        <SortableTh sortBy='created_at' isDefault={true}>Created</SortableTh>
      </NotebookTableHeader>
      <tbody>
      {
        searchResults.map(result => (
          <NotebookTableRow
            key={`searchResult-${ result.userId + '-' + result._id}`}
            to={`/loan-applications/${result._id}${result.loanSlug ? '/loan' : ''}`}
          >
            <td className='font-weight-strong'>{cleanValue(trim(`${result.firstName ?? ''} ${result.lastName ?? ''}`))}</td>
            <td>{cleanValue(formatPhone(result.phone))}</td>
            <td>{cleanValue(result.email)}</td>
            <td>{formatDollars(result.principal, { precision: 0 })}</td>
            <td>{cleanValue(result.status)}</td>
            <td>{cleanValue(result.agentDisplayName)}</td>
            <td>{result.partnerShortName}</td>
            <td>{moment(result.createdAt).format('M/D/YYYY [at] h:mm a')}</td>
          </NotebookTableRow>)
        )
      }
      </tbody>
    </NotebookTable>
  );
}
