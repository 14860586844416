import React from 'react';

const ChevronUp = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2.41499 8.08499C2.30966 7.97952 2.25049 7.83656 2.25049 7.68749C2.25049 7.53843 2.30966 7.39546 2.41499 7.28999L5.60249 4.10249C5.70796 3.99716 5.85093 3.93799 5.99999 3.93799C6.14906 3.93799 6.29202 3.99716 6.39749 4.10249L9.58499 7.28999C9.68435 7.39662 9.73845 7.53766 9.73587 7.68338C9.7333 7.82911 9.67427 7.96815 9.57121 8.07121C9.46815 8.17427 9.32911 8.2333 9.18338 8.23587C9.03766 8.23845 8.89662 8.18435 8.78999 8.08499L5.99999 5.29499L3.20999 8.08499C3.10452 8.19033 2.96156 8.2495 2.81249 8.2495C2.66343 8.2495 2.52046 8.19033 2.41499 8.08499V8.08499Z"
          fill="#495057"/>
  </svg>
);

export default ChevronUp;
