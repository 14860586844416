import React, { useEffect } from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import { Fact } from '../../api/queries/loan-applications/useUnderwritingFacts';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { FactEditModalProps } from '../BorrowerTabs/UnderwritingTab/FactsTable';

type FieldValues = {
  verified: string;
};

type Props = {
  fact: Fact;
};

export default function EditFactVerificationModal (props: Props & FactEditModalProps) {
  const { expand, onSubmit, fact, submitUrl } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${fact.loan_application_id}${submitUrl}`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({
      data: {
        verified: data.verified === 'true',
      },
    });
    await onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    verified: yup.string(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      verified: fact.verified ? 'true' : 'false',
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isSubmitSuccessful, isDirty }, reset, getValues } = methods;

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful, reset, getValues]);

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide} data-qa='edit-fact-modal'>
      <Modal.Header>
        <Modal.Title>
          {fact.human_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='verified' label='Verification Status' controlProps={{ as: 'select' }}>
            <option value='true'>Verified</option>
            <option value='false'>Unverified</option>
          </Field>

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
